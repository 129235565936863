import { getWebServiceParamName, getWebServiceValueFn } from '../conf/conf';
import appStore from '../redux/store/appStore';
import { pathToUrlValue, colorHashTo0xFormat } from '../helpers/google-map-helper';
import { getItem } from '../helpers/object-helper';
import { altQuotationsFromSnapshot } from '../helpers/altquotations-store-helper';
import { getBatteryCodeByLabel } from '../helpers/batteries-store-helper';

/**
 * Returns the http request data for http-client.quotationsGet(body) request
 * @return {Object}
 *  .indirizzo
 *  .pv
 *  .email
 *  .area
 *  .orientamento
 *  .inclinazione
 *  .tipologia
 *  .copertura
 *  .piani
 *  .fabbisogno
 *  .utilizzo
 *  .auto
 *  .center
 *  .zoom
 *  .path
 */
const quotationsGetRequestData = () => {

    const requestData = {};

    // fetch application state to populate data
    const appState = appStore.getState();

    // map data
    let mCenter = null;
    let mZoom = null;
    let mPath = null;

    const map = getItem('map.refs.map', appState);
    if (map) {
        const polygon = getItem('map.refs.polygon', appState);
        const polygonPath = (polygon) ? polygon.getPath() : null;
        const mapCenter = map.getCenter();
        mCenter = (polygon) ? polygon.getCenter().toUrlValue() : mapCenter.toUrlValue() ;
        mZoom = map.getZoom();
        mPath = (polygon) ? pathToUrlValue(polygonPath, {
            color: colorHashTo0xFormat(polygon.fillColor),
            fillcolor: colorHashTo0xFormat(polygon.fillColor, 'AA'),
            weight: 1
        }) : null;
    }

    requestData['center'] = mCenter;
    requestData['zoom'] = mZoom;
    requestData['path'] = mPath;

    // configuration data
    const items = [
        'user.address',
        'user.pv',
        'user.email',
        'configuration.roofArea',
        'configuration.roofOrientation',
        'configuration.roofInclination',
        'configuration.roofTipology',
        'configuration.roofMaterial',
        'configuration.floorsNumber',
        'configuration.electricityConsumption',
        'configuration.houseUsage',
        'configuration.electricCar'
    ];
    for(let item of items) {
        const key = webServiceParamName(item);
        const value = webServiceParamValue(item, getItem(item, appState));
        requestData[key] = value;
    }

    return requestData;

};

/**
 * Returns the http request data for http-client.quotationsGenerate(body) request
 * @return {Object}
 *  .uuid {String} required
 *  .nome {String} required
 *  .cognome {String} required
 *  .indirizzo {String} required
 *  .ragionesociale {String} required
 *  .cap {String} required
 *  .citta {String} required
 *  .telefono {String} required
 *  .email {String} required
 *  .impianto {String} required
 *      ['basic'|'basic-accumulo'|'plus'|'plus-accumulo'|'plus-s'|'plus-s-accumulo']
 *  .consensoAuthorizeA {Boolean} 
 *  .consensoAuthorizeB {Boolean}
 *  .consensoAuthorizeC {Boolean}
 *  .channel {String}
 *  .solarSpecialist {String} optional
 *  .campaign {String} optional
 *  .altpars {Object} optional
 *      .pwr {String}
 *      .id_storage {String}
 */
const quotationGenerateRequestData = () => {

    const requestData = {};

    // fetch application state to populate data
    const appState = appStore.getState();

    // populate data
    requestData.uuid = getItem('quotations.uuid', appState);
    requestData.nome = getItem('quotationsForm.nome', appState);
    requestData.cognome = getItem('quotationsForm.cognome', appState);
    requestData.indirizzo = getItem('quotationsForm.indirizzo', appState);
    requestData.ragionesociale = getItem('quotationsForm.ragionesociale', appState);
    requestData.cap = getItem('user.postalCode', appState);
    requestData.citta = getItem('user.city', appState);
    requestData.telefono = getItem('quotationsForm.telefono', appState);
    requestData.email = getItem('quotationsForm.email', appState);
    requestData.impianto = getItem('quotations.selectedImpianto', appState);
    requestData.electricityConsumptionPeople = getItem('configuration.electricityConsumptionPersone', appState);
    requestData.electricityConsumptionCost = getItem('configuration.electricityConsumptionCost', appState);
    requestData.consensoAuthorizeA = getItem('quotationsForm.consensoAuthorizeA', appState);
    requestData.consensoAuthorizeB = getItem('quotationsForm.consensoAuthorizeB', appState);
    requestData.consensoAuthorizeC = getItem('quotationsForm.consensoAuthorizeC', appState);
    requestData.channel = getItem('applicationContext.ch', appState);

    const solarSpecialist = getItem('applicationContext.ss', appState);
    if (solarSpecialist) {
        requestData.solarSpecialist = solarSpecialist;
    }

    const campaign = getItem('applicationContext.cmp', appState);
    if (campaign) {
        requestData.campaign = campaign;
    }

    const altQuotations = altQuotationsFromSnapshot(
        getItem('quotations.selectedQuotation', appState),
        getItem('quotations.snapshot', appState),
        getItem('bestquotations', appState)
    );
    if (altQuotations) {
        requestData.altpars = {
            pwr: altQuotations.potenza,
            id_storage: getBatteryCodeByLabel(altQuotations.capacitaSistemaAccumulo, getItem('quotations.selectedQuotation', appState))

        };
    }

    // return
    return requestData;
};

/**
 * Returns the name for itemPath item when it is passed as a web service parameter
 * Result is driven by configuration (/conf/conf.js)
 * @param {String} itemPath
 *  Sample
 *      'configuration.roofArea'
 * @return {String}
 *  Sample
 *      'area'
 */
const webServiceParamName = (itemPath) => {
    const webServiceParamName = getWebServiceParamName(itemPath);
    if (webServiceParamName) {
        return webServiceParamName;
    } else {
        const itemPathElements = itemPath.split('.');
        return itemPathElements[itemPathElements.length - 1];
    }
};

/**
 * Returns the value for itemPath item when it is passed as a web service parameter
 * Result is driven by configuration (/conf/conf.js)
 * @param {String} itemPath
 *  Sample
 *      'configuration.roofTipology'
 * @param {Mixed} value
 *  Sample
 *      '1falda'
 * @return {Mixed}
 *  Sample
 *      1
 */
const webServiceParamValue = (itemPath, value) => {
    const webServiceValueFn = getWebServiceValueFn(itemPath);
    return (webServiceValueFn) ? webServiceValueFn(value) : value;
};

export {
    quotationsGetRequestData,
    quotationGenerateRequestData
}