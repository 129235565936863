/**
 * Wizard Component
 * 
 *  Implements the configurator wizard.
 *  Goes from Step1 to Step9 Components.
 * 
 *  Steps validation
 *  
 *      Each step is validated: validation allows or denies the possibility of going to the next step.
 *      Validation is accomplished by the Step<n>.isValidated() method.
 *      Validation (the Step<n>.isValidated() method) is triggered by two different events:
 *          - btn next click
 *          - appStore event dispatch
 * 
 *  Components Dependencies
 * 
 *      Stepzilla
 *      Step1...Step9
 */

import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import { getStepTitle } from '../../conf/conf';

import appStore from '../../redux/store/appStore';
import {
    updateGui,
    guiWizardButtonNavClicked
} from '../../redux/actions/actions';

import StepZilla from "react-stepzilla";
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
//import Step4 from './steps/Step4';
//import Step5 from './steps/Step5';
import Step4 from './steps/Step4';
import Step5 from './steps/Step5';
//import Step8 from './steps/Step8';
//import Step9 from './steps/Step9';

import {
        config as wizardConfig,
        wizardReady as wizardHelperWizardReady,
        customizeButtons as wizardHelperCustomizeButtons,
        assignEvents as wizardHelperAssignEvents
} from '../../helpers/wizard-helper';

import {
    currentUrlAddSearchString,
    navigationByWizardControlsStart
} from '../../helpers/browser-navigation-helper';

import { getItem } from '../../helpers/object-helper';

import './Wizard.css';

const steps = [
    {name: 'Step 1', component: <Step1 />},
    {name: 'Step 2', component: <Step2 />},
    {name: 'Step 3', component: <Step3 />},
    //{name: 'Step 4', component: <Step4 />},
    //{name: 'Step 5', component: <Step5 />},
    {name: 'Step 4', component: <Step4 />},
    {name: 'Step 5', component: <Step5 />},
    //{name: 'Step 8', component: <Step8 />},
    //{name: 'Step 9', component: <Step9 />}
];

/**
 * StepZilla configuration
 */
const conf = {
    stepsNavigation: false,
    nextButtonText: 'Avanti',
    backButtonText: 'Indietro',
    /**
     * Event listener run every time the StepZilla step number changes
     *  1. Dispatches stepNumber
     *  2. Customize wizard nav buttons
     *  3. Dispatch button nav clicked
     * @param {Number} step
     *  The step number 0-indexed (this is why it is incremented step++)
     * @this
     *  this in this method represents the StepZilla configuration Object
     */
    onStepChange: function (step) {
        step++;
        dispatchStepNumber(step);
        wizardHelperCustomizeButtons(step);
    }
};

/**
 * Dispatches the gui.currentStep redux state update
 * @param {Number} step
 */
const dispatchStepNumber = (step) => {
    appStore.dispatch(updateGui({
        currentStep: step,
        currentStepTitle: getStepTitle(step)
    }));
};

class Wizard extends Component {

    constructor(props) {

        super(props);
        
        /**
         * Sets this.state.redirect
         * this.state.redirect is used by custom navigation buttons event handlers to drive the router to the desired React routes
         */
        const setRedirect = function (value) {
            this.setState((state, props) => ({
                redirect: value
            }));
        }.bind(this);

        // configures wizard helper
        wizardConfig({
            setRedirectFn: setRedirect,
            nextButtonValidateFn: () => {
                const data = getItem('configuration.electricityConsumption', appStore.getState());
                return (data !== null);
            },
            /**
             * Event listener for Wizard next button click
             * Starts 'navigation by wizard controls' state
             * 'navigation by wizard controls' state ends as soon as the next Step is mounted
             */
            nextButtonCustomClickListenerFn: (evt) => {
                navigationByWizardControlsStart();
            },
            /**
             * Event listener for Wizard prev button click
             * Starts navigation by wizard controls state
             * 'navigation by wizard controls' state ends as soon as the prev Step is mounted
             */
            prevButtonCustomClickListenerFn: (evt) => {
                navigationByWizardControlsStart();
            }
        });

        // inits state
        this.state = {
            redirect: false
        };

    }

    /**
     *  1. Dispatches step number 1
     *  2. As soon as the StepZilla wizard is dom available:
     *      1. Launches the customize buttons functionality
     *      2. Assigns wizard helper events
     */
    componentDidMount() {
        dispatchStepNumber(1);
        wizardHelperWizardReady(() => {
            wizardHelperCustomizeButtons();
            wizardHelperAssignEvents();
        });
    }

    /**
     *  1. Dispatches step number 0
     */
    componentWillUnmount() {
        dispatchStepNumber(0);
    }

    /**                                                                                    render 
     * ==========================================================================================
     */

    render() {
        //console.log('Wizard.render()');
        //console.log('    this.state.redirect = ', this.state.redirect);
        if (this.state.redirect !== false) {
            return <Redirect to={this.state.redirect} />;
        } else {
            return (
                <div className='step-progress slc-wizard'>
                    <StepZilla
                        steps={steps}
                        stepsNavigation={conf.stepsNavigation}
                        nextButtonText={conf.nextButtonText}
                        backButtonText={conf.backButtonText}
                        nextTextOnFinalActionStep={conf.nextTextOnFinalActionStep}
                        onStepChange={conf.onStepChange} />
                </div>
            );
        }
    }

    
    
}

export default withRouter(Wizard);