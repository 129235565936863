/**
 * App Component
 * @Dependencies
 *  https://materializecss.com/
 *    http://fullstackhybrid.com/using-materializecss-with-reactjs/
 */

import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";

import PageHome from './components/pages/PageHome';
import PageWizard from './components/pages/PageWizard';
import PageQuotations from './components/pages/quotations/PageQuotations';

import { getConfUrlBasePathName } from './conf/conf';

import gaSetup from './helpers/google-analytics-helper';
import { setup as mobileDetectorSetup} from './helpers/mobile-detector';
import { urlContextInit } from './helpers/browser-navigation-helper';

import 'materialize-css/dist/css/materialize.min.css';
import './App.css';
import './fonts/novecentosanswide_book/stylesheet.css';
import './fonts/novecentosanswide_medium/stylesheet.css';
import './libs/ak-toastr/ak-toastr.min.css';

// Setup google analytics
gaSetup();

// Set up mobile detector
mobileDetectorSetup();

// Init url context
urlContextInit();

// Export App
class App extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <Router basename={ getConfUrlBasePathName() }>
        <Route path="/" exact component={PageHome} />
        <Route path="/wizard/" component={PageWizard} />
        <Route path="/preventivo/" component={PageQuotations} />
      </Router>
    );
  }
}

export default App;
