import React, { useState } from 'react';

import useNavPath from '../../../hooks/useNavPath';

import './FormResponse.css';

import successImg from '../../../images/quotations/icon-success.png';
import failImg from '../../../images/quotations/icon-fail.png';

/**
 * @prop {Object} quotationResponse
 * @prop {Function} quotationResponseReset
 */
const FormResponse = (props) => {

    const [navPath, setNavPath, navigateToNavPath] = useNavPath();

    const onClickHome = (evt) => {
        setNavPath('/');
    };

    const iconImg = (props.quotationResponse.result === 'fail') ? failImg : successImg;

    if (navPath !== null) {
        return navigateToNavPath();
    } else {
        return (props.quotationResponse.result !== null) ?
            <div className="slc-quotations-form-response">
                <img className="slc-quotations-form-response__icon" src={iconImg} />
                <div className="slc-quotations-form-response__text" dangerouslySetInnerHTML={{ __html: props.quotationResponse.message }} />
                <div className="slc-quotations-form-response__buttons">
                    {props.quotationResponse.result === 'fail'
                        ? <button className="btn btn-next btn-primary btn-lg" onClick={props.quotationResponseReset}>Riprova</button>
                        : <button className="btn btn-next btn-primary btn-lg" onClick={props.quotationResponseReset}>Richiedi nuovo preventivo</button>
                    }
                </div>
            </div> :
            null;
    }

}

export default FormResponse;