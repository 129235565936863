/**
 * QuotationsDesktop Component
 * 
 * Desktop targeted QuotationsDesktop
 */

import React, { Component, Fragment } from 'react';

import appStore from '../../../redux/store/appStore';

import { getConfItem } from '../../../conf/conf';

import HtmlInput from '../../HtmlInput/HtmlInput';
import QuotationImpossibleMessage from './QuotationImpossibleMessage';

import { getItem } from '../../../helpers/object-helper';
import {
    getHtmlSelectDataFromReduxState,
    getHtmlSelectValueFromReduxState,
    getInputsSnapshot
} from '../../../helpers/quotations-helper';

import {
    nextSunday,
    dateFormat
} from '../../../helpers/date-helper';

import './QuotationsDesktop.css';

import quotationBasicImg from '../../../images/quotations/quotation-basic.jpg';
import quotationPlusImg from '../../../images/quotations/quotation-plus.jpg';
import quotationBasicFlatImg from '../../../images/quotations/quotation-basic-flat.jpg';
import quotationPlusFlatImg from '../../../images/quotations/quotation-plus-flat.jpg';

const imagesBasePath = getConfItem('gui.images.basePath');
const imgLabelsDummyUrl = '/images/quotations-labels-dummy.jpg';
const docsBasePath = getConfItem('gui.docs.basePath');

class QuotationsDesktop extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mapStaticImageUrl: props.mapStaticImageUrl,
            loading: props.loading,
            config: props.config,
            valutazione: props.valutazione,
            data: props.data,
            onSubmit: props.onSubmit,
            onCheckboxChange: props.onCheckboxChange,
            onSelectChange: props.onSelectChange,
            onQuoteChooseButtonClick: props.onQuoteChooseButtonClick
        };
    }

    componentDidMount() {
        this.labelInfoSetup();
    }

    /**
     * React getDerivedStateFromProps(nextProps, prevState) lifecycle method implementation
     * Updates this.state.mapStaticImageUrl
     * @param {*} nextProps 
     * @param {*} prevState 
     */
    static getDerivedStateFromProps(nextProps, prevState) {
        return Object.assign(prevState, {
            valutazione: nextProps.valutazione,
            mapStaticImageUrl: nextProps.mapStaticImageUrl
        });
    }

    getConfig() {
        return this.props.config;
    }

    getBasicImage() {
        const roofInclination = getItem('configuration.roofInclination', appStore.getState());
        return (roofInclination === "0") ? quotationBasicFlatImg : quotationBasicImg;
    }

    getPlusImage() {
        const roofInclination = getItem('configuration.roofInclination', appStore.getState());
        return (roofInclination === "0") ? quotationPlusFlatImg : quotationPlusImg;
    }

    /**
     * =========================================================================== label infos popups management
     * Relies on
     *  this.labelInfoCreated {Object}
     *      Sample
     *          {
     *              'ottimizzatori': {HTMLElement}
     *          }
     *  this.labelInfoShowedNow {String}
     *      Sample
     *          'ottimizzatori'
     */

    /**
     * Sets label info management setup
     */
    labelInfoSetup() {

        const responsiveState = getItem('gui.responsive', appStore.getState());

        // label infos related properties
        this.labelInfoCreated = {};
        this.labelInfoShowedNow = null;

        // label infos click handler
        this.onLabelInfoEvent = this.onLabelInfoEvent.bind(this);

        if (responsiveState !== 'desktop') {
            // page click handler (to delete currently shown label infos when clicking anywhere in the page)
            this.onLabelInfoClickAnywhere = this.props.onLabelInfoClickAnywhere.bind(this);
            document.querySelector('.slc-page-quotations').addEventListener('click', this.onLabelInfoClickAnywhere);
        }

    }

    /**
     * Event handler for label info icons
     * The event type depends on the gui responsive state:
     *  - mouseover/mouseout for desktop devices
     *  - click for mobile/tablet devices
     * If some info is found for the label item, the info popup (content) creation/deletion is managed
     * @param {Event} evt 
     */
    onLabelInfoEvent(evt) {
        const dataId = evt.target.getAttribute('data-id');
        const labelInfo = getItem(dataId + '.info', this.getConfig());
        if (labelInfo) {
            this.labelInfoContentManage(dataId, labelInfo, evt.target);
        }
    }

    /**
     * Manages label infos popups
     * @param {String} dataId
     *  Sample
     *      'ottimizzatori'
     * @param {String} htmlContent 
     * @param {HTMLElement} labelInfoIconElement 
     */
    labelInfoContentManage(dataId, htmlContent, labelInfoIconElement) {
        if (this.labelInfoCreated[dataId]) {
            this.labelInfoContentDelete(dataId);
        } else {
            if (this.labelInfoShowedNow) {
                this.labelInfoContentDelete(this.labelInfoShowedNow);
            }
            this.labelInfoContentCreate(dataId, htmlContent, labelInfoIconElement)
        }
    }

    /**
     * Creates label info popup for dataId label
     * @param {String} dataId
     *  Sample
     *      'ottimizzatori'
     * @param {String} htmlContent 
     * @param {HTMLElement} labelInfoIconElement 
     */
    labelInfoContentCreate(dataId, htmlContent, labelInfoIconElement) {
        const infoContent = document.createElement('div');
        infoContent.setAttribute('class','slc-data-label-info-content slc-position-vertical-middle');
        infoContent.innerHTML = htmlContent;
        infoContent.addEventListener('click', function() {
            this.labelInfoContentDelete(dataId);
        }.bind(this));
        labelInfoIconElement.appendChild(infoContent);
        this.labelInfoCreated[dataId] = infoContent;
        this.labelInfoShowedNow = dataId;
    }

    /**
     * Deletes label info popup for dataId label
     * @param {String} dataId
     *  Sample
     *      'ottimizzatori'
     * @param {String} htmlContent 
     * @param {HTMLElement} labelInfoIconElement 
     */
    labelInfoContentDelete(dataId) {
        const infoElement = document.querySelector('.slc-data-' + dataId + ' .slc-data-label-info');
        const infoContent = document.querySelector('.slc-data-' + dataId + ' .slc-data-label-info-content');
        infoElement.removeChild(infoContent);
        this.labelInfoCreated[dataId] = null;
        delete this.labelInfoCreated[dataId];
        this.labelInfoShowedNow = null;
    }
    
    /**
     * =========================================================================== render
     */

    /**
     * Renders the images row JSX
     */
    renderImagesRow() {
        return (
            <div className="slc-quotations-row slc-quotations-row-images">
                <div className="slc-quotations-cell slc-quotations-cell-image">
                    <img className="slc-quotations-image" src={this.state.mapStaticImageUrl} alt="" />
                </div>
                <div className="slc-quotations-cell slc-quotations-cell-image">
                    <img className="slc-quotations-image" src={this.getBasicImage()} alt="" />
                </div>
                <div className="slc-quotations-cell slc-quotations-cell-image">
                    <img className="slc-quotations-image" src={this.getPlusImage()} alt="" />
                </div>
            </div>
        );
    }

    /**
    renderImagesRow() {
        return (
            <div className="slc-quotations-row slc-quotations-row-images">
                <div className="slc-quotations-cell slc-quotations-cell-image">
                    <div className="slc-quotations-sconto-ecobonus">
                        <img src={`${ imagesBasePath }/sconto-ecobonus-300.png`} />
                    </div>
                    <img className="slc-quotations-image" src={this.state.mapStaticImageUrl} alt="" />
                </div>
                <div className="slc-quotations-cell slc-quotations-cell-image">
                    <img className="slc-quotations-image" src={this.getBasicImage()} alt="" />
                </div>
                <div className="slc-quotations-cell slc-quotations-cell-image">
                    <div className="slc-quotation-best-choice">
                        Il più richiesto!
                    </div>
                    <img className="slc-quotations-image" src={this.getPlusImage()} alt="" />
                </div>
            </div>
        );
    }
     */

    /**
     * Renders the JSX representing all the rows with the quotations data
     *  data label - data value basic - data value plus
     *  Data is retrieved the following priority rules:
     *      1. From data[dataId] (data is passed down from PageQuotations.state.data), if any
     *      2. From config[dataId].values, if any
     *      3. Defaults to {basic: '', plus: ''}, if none of the preceeding rules is matched
     */
    renderData() {
        
        const config = this.getConfig();
        const data = this.props.data;
        const jsx = [];

        for (let dataId in config) {

            let rowLabel = config[dataId].label;
            
            let rowData;
            if (data && data[dataId]) {
                rowData = data[dataId];
            } else {
                rowData = (config[dataId] && config[dataId].values) ? config[dataId].values : { basic: '', plus: '' };
            }
            
            const rowGuiElementTypes = (config[dataId] && config[dataId].guiHtmlElementTypes) ?
                config[dataId].guiHtmlElementTypes :
                { basic: 'HtmlLabel', plus: 'HtmlLabel' };

            jsx.push(this.renderDataRow(dataId, rowLabel, rowData, rowGuiElementTypes));

        }

        return jsx;

    }

    /**
     * Renders a data row
     *  data label - data value basic - data value plus
     * Data rows include all data coming from the server, from 'Sistema Solare' to 'Scegli il tuo sistema'
     * @param {String} dataId 
     *  Sample
     *      'sistemaSolare'
     * @param {String} label 
     *  Sample
     *      'Sistema solare'
     * @param {Object} values
     *  .basic {Mixed}
     *  .plus {Mixed}
     *  Sample
     *      {
     *          basic: 'IKEA Solstrale Basic',
     *          plus: 'IKEA Solstrale Plus'
     *      }
     * @param {Object} guiElementTypes
     *  .basic {String} optional
     *  .plus {String} optional
     *  Samples
     *      Only the plus value is managed as a HtmlCheckbox
     *      {
     *          plus: 'HtmlCheckbox'
     *      } 
     * 
     *      Boht the basic and the plus values are managed as a HtmlSelect
     *      {
     *          basic: 'HtmlSelect'
     *          plus: 'HtmlSelect'
     *      }
     * 
     * @usage
     *  this.renderDataRow('sistemaSolare', 'Sistema solare', {basic:  'IKEA Solstrale Basic', plus: 'IKEA Solstrale Plus'})
     *  this.renderDataRow('potenza', 'Potenza', {basic: '5,23 kWp', plus: '5,40 kWp'})
     *  this.renderDataRow('numeroPannelli', 'Numero pannelli', {basic: 19, plus: 18})
     */
    renderDataRow(dataId, label, values, guiElementTypes) {

        // are quotations (basic and plus) impossible?
        const quotationBasicImpossible = (this.state.valutazione && this.state.valutazione.basic === -1);
        const quotationPlusImpossible = (this.state.valutazione && this.state.valutazione.plus === -1);

        const valueGuiFn = getItem(dataId + '.valueGuiFn', this.getConfig());
        const labelInfo = getItem(dataId + '.info', this.getConfig());
        
        // value basic
        var valueBasic;
        if (quotationBasicImpossible && dataId !== 'sistemaSolare') {
            valueBasic = '--';
        } else {
            valueBasic = (valueGuiFn) ? valueGuiFn(values.basic, {
                quotationType: 'basic',
                values,
                inputsSnapshot: getInputsSnapshot()
            }) : values.basic ;
        }

        // value plus
        var valuePlus;
        if (quotationPlusImpossible && dataId !== 'sistemaSolare') {
            valuePlus = '--';
        } else {
            valuePlus = (valueGuiFn) ? valueGuiFn(values.plus, {
                quotationType: 'plus',
                values,
                inputsSnapshot: getInputsSnapshot()
            }) : values.plus ;
        }

        // value basic JSX
        const valueBasicJSX = (values.basic) ? (
            <div className="slc-data-value slc-data-value-basic">
            { valueBasic }
            </div>) : null;

        // value plus JSX
        const valuePlusJSX = (values.plus) ? (
            <div className="slc-data-value slc-data-value-plus">
            { valuePlus }
            </div>) : null;

        // quotation basic impossible JSX
        const quotationImpossibleMessageBasicJSX = (quotationBasicImpossible && dataId == 'sistemaSolare') ?
            <QuotationImpossibleMessage message={`L' impianto ${valueBasic} non è realizzabile con le caratteristiche che hai scelto`} /> :
            null ;

        // quotation plus impossible JSX
        const quotationImpossibleMessagePlusJSX = (quotationPlusImpossible && dataId == 'sistemaSolare') ?
        <QuotationImpossibleMessage message={`L' impianto ${valuePlus} non è realizzabile con le caratteristiche che hai scelto`} /> :
            null ;

        // value input basic JSX
        const valueInputBasicJSX = (!quotationPlusImpossible) ?
            this.renderDataValueInput(dataId, 'basic', guiElementTypes['basic']) :
            null ;

        // value input plus JSX
        const valueInputPlusJSX = (!quotationPlusImpossible) ?
            this.renderDataValueInput(dataId, 'plus', guiElementTypes['plus']) :
            null ;

        if (dataId === 'scegli') {
            //label = '';
            //label = `*Offerta valida fino al ${ dateFormat(nextSunday()) }`;
            label = this.renderNavButtons();
        } else {
            label = <div dangerouslySetInnerHTML={ {__html: label} } className="slc-data-label-text" />;
        }

        // Label Info is handled onClick for mobile and onMouseOver/onMouseOut for desktop
        let labelInfoIconJSX = null;
        if (labelInfo) {
            if (getItem('gui.responsive', appStore.getState()) === 'desktop') {
                labelInfoIconJSX = <div className="slc-data-label-info" data-id={dataId} onMouseOver={this.onLabelInfoEvent} onMouseOut={this.onLabelInfoEvent}>?</div>
            } else {
                labelInfoIconJSX = <div className="slc-data-label-info" data-id={dataId} onClick={this.onLabelInfoEvent}>?</div>
            }
        }

        // css class for this row
        const dataClassName = 'slc-quotations-row slc-quotations-row-data slc-data-' + dataId;

        return (
            <div key={dataId} className={dataClassName}>
                <div className="slc-quotations-cell slc-quotations-cell-data">
                    <div className="slc-data-label">
                    { label }
                    { labelInfoIconJSX }
                    </div>
                </div>
                <div className="slc-quotations-cell slc-quotations-cell-data">
                    { valueBasicJSX }
                    { quotationImpossibleMessageBasicJSX }
                    { valueInputBasicJSX }
                </div>
                <div className="slc-quotations-cell slc-quotations-cell-data">
                    { valuePlusJSX }
                    { quotationImpossibleMessagePlusJSX }
                    { valueInputPlusJSX }
                </div>
            </div>
        );

        /**
        return (
            <div key={dataId} className={dataClassName}>
                <div className="slc-quotations-cell slc-quotations-cell-data">
                    <div className="slc-data-label">
                    <span dangerouslySetInnerHTML={{
                        __html: label
                      }}></span>
                    {labelInfoIconJSX}
                    </div>
                </div>
                <div className="slc-quotations-cell slc-quotations-cell-data">
                    { valueBasicJSX }
                    { quotationImpossibleMessageBasicJSX }
                    { valueInputBasicJSX }
                </div>
                <div className="slc-quotations-cell slc-quotations-cell-data">
                    { valuePlusJSX }
                    { quotationImpossibleMessagePlusJSX }
                    { valueInputPlusJSX }
                </div>
            </div>
        );
         */
        
    }

    renderDataValueInput(dataId, dataValueType, dataElementType) {

        switch (dataElementType) {

            case 'HtmlCheckbox':
                return <HtmlInput
                    type={ dataElementType }
                    className="slc-checkbox"
                    onChange={ this.state.onCheckboxChange }
                    wrapper={{
                        tag: 'div',
                        attributes: 
                            {
                                className: `slc-data-value-checkbox slc-data-value-checkbox--${ dataId }-${ dataValueType }`,
                                'data-option-id': `${ dataId }-${ dataValueType }`
                            }
                        
                    }} />
            
            case 'HtmlSelect':

                let options = getHtmlSelectDataFromReduxState(appStore.getState(), dataId, dataValueType);
                let value = getHtmlSelectValueFromReduxState(appStore.getState(), dataId, dataValueType);

                return <HtmlInput
                    type={ dataElementType }
                    className="slc-select"
                    onChange={ this.state.onSelectChange }
                    options={ options }
                    value={ value }
                    wrapper={{
                        tag: 'div',
                        attributes: 
                            {
                                className: `slc-data-value-select slc-data-value-select--${ dataId }-${ dataValueType }`,
                                'data-option-id': `${ dataId }-${ dataValueType }`
                            }
                        
                    }} />

            case 'HtmlPlusMinus':

                let items = getHtmlSelectDataFromReduxState(appStore.getState(), dataId, dataValueType);
                let val = getHtmlSelectValueFromReduxState(appStore.getState(), dataId, dataValueType);

                return <HtmlInput
                    type={ dataElementType }
                    className="slc-plus-minus"
                    onClickPlusButton={ this.state.onSelectChange }
                    onClickMinusButton={ this.state.onSelectChange }
                    items={ items }
                    value={ val }
                    wrapper={{
                        tag: 'div',
                        attributes: 
                            {
                                className: `slc-data-value-select slc-data-value-select--${ dataId }-${ dataValueType }`,
                                'data-option-id': `${ dataId }-${ dataValueType }`
                            }
                        
                    }} />

            case 'HtmlButton':
                return <HtmlInput
                    type={ dataElementType }
                    className="slc-button-quotation slc-button btn btn-primary btn-lg"
                    label="Ricevi più dettagli"
                    onClick={ this.state.onQuoteChooseButtonClick }
                    wrapper={{
                        tag: 'div',
                        attributes: 
                            {
                                className: `slc-data-value-button slc-data-value-button--${ dataId }-${ dataValueType }`,
                                'data-option-id': `${ dataId }-${ dataValueType }`
                            }
                        
                    }} />
            default:
                return null;
        }

    }

    renderNavButtons() {
        return (
            <div className="slc-data-label slc-quotations-nav-buttons-container">
                <button
                    className="slc-quotations-nav-button btn btn-next btn-primary btn-lg"
                    id="slc-btn-backToWizard"
                    onClick={ this.props.navigateBack }>
                    Indietro
                </button>
                <button
                    className="slc-quotations-nav-button btn btn-next btn-primary btn-lg"
                    id="slc-btn-restart"
                    onClick={ this.props.navigateToWizard }>
                    Restart
                </button>
            </div>
        );
    }

    renderAdditionalRows() {
        return null;
        /*
        return (
            <Fragment>
                <div className="slc-quotations-row slc-quotations-row-generic slc-quotations-schedaprodotti-link">
                    <div className="slc-quotations-cell">
                        <div className="slc-data-label">
                            <a href={`${ docsBasePath }/Scheda tecnica componenti impianti IKEA - Wolmann rev09giu21.pdf`} target="_blank">Scheda tecnica dei prodotti</a>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
        */
    }

    render() {
        return (
            <div className='slc-quotations'>
                {this.renderImagesRow()}
                {this.renderData()}
                {this.renderAdditionalRows()}
            </div>
        );
    }
}

export default QuotationsDesktop;