const aktoastr = require('./ak-toastr/ak-toastr');

aktoastr.config({
    toastrOptions: {
        positionClass: 'toast-center',
        noIcon: true,
        tapToDismiss: false, // disables hide on click
        preventDuplicates: true,
        timeOut: 3500,
        extendedTimeOut: 0
        //noAutoHide: true
    }
});

const warn = (message) => {
    aktoastr.render({
        type: 'warning',
        message: message
    });
};

const error = (message) => {
    aktoastr.render({
        type: 'error',
        message: message
    });
};

const info = (message) => {
    aktoastr.render({
        type: 'info',
        message: message
    });
};

const ok = (message) => {
    aktoastr.render({
        type: 'success',
        message: message
    });
};

export {
    warn,
    error,
    info,
    ok
};