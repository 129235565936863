/**
 * Helper module for Wizard React Component
 * 
 * @public
 *  config()
 *  wizardReady(callback)
 *      When Wizard is dom ready, call callback
 *  customizeButtons()
 *      Customize the Wizard navigation buttons to handle the following limit cases:
 *          - a custom prev button creation at step 1
 *          - a custom next button creation at step 9
 *      This is necessary because ReactStepzilla does not implement those cases
 *  assignEvents()
 *      Assigns events
 *  nextAuto(currentStepComponent, currentStepNum)
 *      Automatically proceeds to next Step if that is the case
 *  nextPermissionManage(currentStepComponent, currentStepNum, showWarnMessage = false)
 *      Manages the permission to navigate to the next Step
 *  nextPermissionAllowedGuiFeedback(currentStepComponent, currentStepNum)
 *      Renders gui when navigation to next step is allowed
 *  nextPermissionDeniedGuiFeedback(currentStepComponent, currentStepNum, showWarnMessage)
 *      Renders gui when navigation to next step is denied   
 */

import appStore from '../redux/store/appStore';
import { getConfItem } from '../conf/conf';
import { warn as guiFeedbackWarn } from '../libs/gui-feedback';

var setRedirect = null;
var nextButtonValidateFn = null;
var nextButtonCustomClickListenerFn = null;
var prevButtonCustomClickListenerFn = null;

/**
 * Configures the wizard helper, assigning needed functions
 * @param {Object} options
 *  .setRedirectFn {Function}
 *  .nextButtonValidateFn {Function}
 */
const config = function(options) {
    setRedirect = options.setRedirectFn;
    nextButtonValidateFn = options.nextButtonValidateFn;
    nextButtonCustomClickListenerFn = options.nextButtonCustomClickListenerFn;
    prevButtonCustomClickListenerFn = options.prevButtonCustomClickListenerFn;
};

/**
 * As soon as the StepZilla wizard is ready (dom available), calls callback function
 * The availability check succeeds if #prev-button is dom available
 * @param {Function} callback 
 */
const wizardReady = (callback) => {
    const proc = setInterval(() => {
        const stepzilla = document.getElementById('prev-button');
        if (stepzilla) {
            clearInterval(proc);
            callback();
        }
    }, 10);
};

const assignEvents = () => {
    domGetNextButton().addEventListener('click', nextButtonCustomClickListenerFn);
    domGetPrevButton().addEventListener('click', prevButtonCustomClickListenerFn);
};

/**
 * Customizes the navigation buttons
 * The customization handles:
 *  - a custom prev button creation at step 1
 *  - a custom next button creation at step 9
 * @param {Number} step 
 */
const customizeButtons = (step = 1) => {
    customizeNextButton(step);
    customizePrevButton(step);
};

/**
 * Returns the HTMLElement representing the navigation buttons parent
 * @return HTMLElement
 */
const domGetButtonsParent = () => {
    return document.querySelector('.footer-buttons');
};

/**                                                                         synch step to url 
 * ==========================================================================================
 */

/**
 * Synchs the shown Wizard Step to the url
 * It is necessary to use the setTimeout logic, because at the time of the execution of this function:
 *  1. The shown Step Component is already the stepNum Step (the current Step)
 *  2. BUT, the current url (document.location.href) is still the previous Step one (StepZilla has not yes updated the current url)
 * So, we have to wait for StepZilla to update the current url to the current step: we wait with a setTimeout structure
 * @param {ReactComponent} StepComponent 
 * @param {Number} stepNum 
 */
const stepSynchToUrl = (StepComponent, stepNum) => {

    // wait (50ms) for ReactStepZilla to set the current Step url
    setTimeout(() => {

        // see if we are at the current step (shown Wizard Step and url match each other)
        const pattern = stepUrlPatternGet(stepNum);
        const isCurrentStep = pattern.test(document.location);

        // if we are not in the current step -> move the Wizard to the Step represented in the current url
        if (!isCurrentStep) {
            const pattern = /step(\d)/;
            const url = document.location.href;
            const synchToStepNum = url.match(pattern) && url.match(pattern)[1];
            if (synchToStepNum) {
                StepComponent.props.jumpToStep(parseInt(synchToStepNum) - 1);
            }
        } else {
            //console.log('WE ARE IN THE CURRENT STEP');
        }

    }, 50);

};

const stepUrlPatternGet = (stepNum) => {
    return new RegExp(`wizard\/step${stepNum}`);
};

const stepUrlPathGet = (stepNum) => {
    return '/wizard/step' + stepNum;
};

/**                                                                                 next auto 
 * ==========================================================================================
 */

/**
 * Automatically proceeds to next Step if that is the case
 * The current Step must be validated (user must have chosen a valid value) to proceed
 * This functionality is skipped for Step 1 (it is meaningless for that step)
 * @param {React Component} currentStepComponent 
 * @param {Number} currentStepNum 
 */
const nextAuto = (currentStepComponent, currentStepNum) => {
    if (currentStepNum === 1) {
        return;
    } else {
        if (currentStepComponent.isValidated(false)) {
            goNext(currentStepComponent, currentStepNum);
        }
    }
};

/**
 * Navigates to the next Step
 * @param {React Component} currentStepComponent 
 * @param {Number} currentStepNum 
 */
const goNext = (currentStepComponent, currentStepNum) => {
    // get next button (it is a customized button for Step5)
    const nextButton = (currentStepNum < 5) ? document.getElementById('next-button') : document.getElementById('next-button-clone') ;
    if (nextButton) {
        nextButton.click();
    }
};

/**
 * Navigates to the prev Step (not needed so far)
 */
const goPrev = (currentStepComponent, currentStepNum) => {};

/**                                                                              next allowed 
 * ==========================================================================================
 */

/**
 * Manages the permission to navigate to the next Step
 * If the current Step is validated, permission is granted
 * If the current Step is not validated, permission is revoked
 * @param {React Component} currentStepComponent 
 * @param {Number} currentStepNum 
 * @param {Boolean} showWarnMessage 
 */
const nextPermissionManage = (currentStepComponent, currentStepNum, showWarnMessage = false) => {
    const currentStepValidated = currentStepComponent.isValidated(showWarnMessage);
    if (currentStepValidated) {
        nextPermissionAllowedGuiFeedback(currentStepComponent, currentStepNum);
    } else {
        nextPermissionDeniedGuiFeedback(currentStepComponent, currentStepNum, showWarnMessage);
    }
};

/**
 * Renders gui when navigation to next step is allowed
 * @param {React Component} currentStepComponent 
 * @param {Number} currentStepNum 
 */
const nextPermissionAllowedGuiFeedback = (currentStepComponent, currentStepNum) => {
    if (!domGetNextButton()) {
        return;
    }
    const button = (domGetNextButton().style.display !== 'none') ? domGetNextButton() : domGetNextButtonClone();
    if (button) {
        button.style.opacity = 1;
    }
};

/**
 * Renders gui when navigation to next step is denied
 * @param {React Component} currentStepComponent 
 * @param {Number} currentStepNum 
 */
const nextPermissionDeniedGuiFeedback = (currentStepComponent, currentStepNum, showWarnMessage) => {
    if (showWarnMessage) {
        guiFeedbackWarn(getConfItem('gui.steps.step' + currentStepNum + '.nextValidationFailMessage'));
    } 
    if (!domGetNextButton()) {
        return;
    }
    const button = (domGetNextButton().style.display !== 'none') ? domGetNextButton() : domGetNextButtonClone();
    if (button) {
        button.style.opacity = .4;
    }
};

/**                                                                        custom prev button 
 * ==========================================================================================
 */

/**
 * Customizes the navigation prev button
 * At step 1        -> creates the prev button clone
 * At other steps   -> deletes the prev button clone
 * @param {Number} step 
 */
const customizePrevButton = (step) => {
    if (step === 1) {
        domPrevButtonCustomCreate();
    } else {
        domPrevButtonCustomDelete()
    }
};

/**
 * Returns the HTMLElement representing the navigation prev button
 * @return HTMLElement
 */
const domGetPrevButton = () => {
    return document.getElementById('prev-button');
};

/**
 * Returns the HTMLElement representing the custom prev button
 * @return HTMLElement|null
 */
const domGetPrevButtonClone = () => {
    return document.getElementById('prev-button-clone');
};

/**
 * Creates the custom prev button
 */
const domPrevButtonCustomCreate = () => {
    if (!domGetPrevButtonClone()) {
        domGetButtonsParent().appendChild(domPrevButtonClone());
    }
};

/**
 * Deletes the custom prev button, if it exists
 */
const domPrevButtonCustomDelete = () => {
    const clone = domGetPrevButtonClone();
    if (clone) {
        domGetButtonsParent().removeChild(clone);
    }
};

/**
 * Creates and returns the prev button custom clone, not attached to the dom yet
 * The clone click handler redirects to the '/' route
 * @return {HTMLElement}
 */
const domPrevButtonClone = () => {
    const clone = domGetPrevButton().cloneNode();
    clone.setAttribute('id', 'prev-button-clone');
    clone.style.display = 'block';
    clone.innerHTML = 'Indietro';
    clone.addEventListener('click', evt => {
        domPrevButtonCustomDelete();
        setRedirect('/');
    });
    return clone;
};

/**                                                                        custom next button 
 * ==========================================================================================
 */

/**
 * Customizes the navigation next button
 * At step 9        -> creates the next button clone
 * At other steps   -> deletes the next button clone
 * @param {Number} step 
 */
const customizeNextButton = (step) => {
    if (step === 5) {
        domNextButtonCustomCreate();
    } else {
        domNextButtonCustomDelete();
    }
};

/**
 * Returns the HTMLElement representing the navigation prev button
 * @return HTMLElement
 */
const domGetNextButton = () => {
    return document.getElementById('next-button');
};

/**
 * Returns the HTMLElement representing the custom next button
 * @return HTMLElement|null
 */
const domGetNextButtonClone = () => {
    return document.getElementById('next-button-clone');
};

/**
 * Creates the custom prev button
 */
const domNextButtonCustomCreate = () => {
    domGetButtonsParent().appendChild(domNextButtonClone());
};

/**
 * Deletes the custom next button, if it exists
 */
const domNextButtonCustomDelete = () => {
    const clone = domGetNextButtonClone();
    if (clone) {
        domGetButtonsParent().removeChild(clone);
    }
};

/**
 * Creates and returns the next button custom clone, not attached to the dom yet
 * The clone click handler redirects to the '/results' route
 * @return {HTMLElement}
 */
const domNextButtonClone = () => {
    const clone = domGetNextButton().cloneNode();
    clone.setAttribute('id', 'next-button-clone');
    clone.style.display = 'block';
    //clone.style.opacity = (appStore.getState().configuration.electricCar === 'Si') ? 1 : .4;
    clone.style.opacity = (appStore.getState().configuration.electricityConsumption !== null) ? 1 : .4;
    clone.innerHTML = 'Avanti';
    clone.addEventListener('click', evt => {
        if (nextButtonValidateFn()) {
            setRedirect('/preventivo');
        } else {
            nextPermissionDeniedGuiFeedback(null, 5, true);
        }        
    });
    return clone;
};

export {
    config,
    wizardReady,
    customizeButtons,
    assignEvents,
    stepSynchToUrl,
    stepUrlPatternGet,
    stepUrlPathGet,
    nextAuto,
    nextPermissionManage,
    nextPermissionAllowedGuiFeedback,
    nextPermissionDeniedGuiFeedback
};