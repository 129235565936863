/**
 * PageQuotationsDesktop Component
 * 
 * Desktop targeted PageQuotation
 */

import React, { Component, Fragment } from 'react';

import QuotationsDesktop from './QuotationsDesktop';
import Hints from './Hints';
import Form from './Form';

class PageQuotationsDesktop extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Fragment>
                <div className="col s12 l12 slc-page-quotations-main">
                    <QuotationsDesktop
                        mapStaticImageUrl= { this.props.mapStaticImageUrl }
                        loading={ this.props.loading }
                        config={ this.props.config }
                        valutazione={ this.props.valutazione }
                        data={ this.props.data }
                        navigateBack = { this.props.navigateBack }
                        navigateToWizard = { this.props.navigateToWizard }
                        onCheckboxChange={ this.props.onCheckboxChange }
                        onSelectChange={ this.props.onSelectChange }
                        onQuoteChooseButtonClick={ this.props.onQuoteChooseButtonClick }
                        onLabelInfoClickAnywhere={ this.props.onLabelInfoClickAnywhere } />
                    <Hints />
                </div>
                <div className="col s12 l4 slc-page-quotations-form hide">
                    <Form />
                </div>
            </Fragment>
        );
    }
}

export default PageQuotationsDesktop;