var toastr = require('toastr');

var _toastrOptions;
const _defaultType = 'info';
const _defaultMessage = 'Hi! I am ak-toastr';

const config = (options) => {
    _cssInject(options.css);
    setToastrOptions(options.toastrOptions);
};

const _cssInject = (css) => {
    if (css && css.aktoastrCssPath) {
        _toastrCssInject(css.aktoastrCssPath);
    }
}

/**
 * Injects cssFilePath file into the html page
 * Assumes cssFilePath exists
 * @param {String} cssFilePath
 *  Sample
 *      /dist/toastr.min.css
 */
const _toastrCssInject = (cssFilePath) => {
    var link = document.createElement( "link" );
    link.href = cssFilePath;
    link.type = 'text/css';
    link.rel = 'stylesheet';
    document.getElementsByTagName('head')[0].appendChild(link);
};

const setToastrOptions = (toastrOptions) => {

    // exit if no toastrOptions
    if (!toastrOptions) {
        return;
    }

    // assigns toastrOptions
    _toastrOptions = toastrOptions;

    // manages ak-toastr options

    _toastrOptions.positionClass += ' ak-toastr-container';

    if (_toastrOptions.noIcon && _toastrOptions.noIcon === true) {
        _toastrOptions.positionClass += ' no-icon';
    }
    if (_toastrOptions.noAutoHide && _toastrOptions.noAutoHide === true) {
        _toastrOptions.timeOut = '0';
        _toastrOptions.extendedTimeOut = '0';
    }

};

/**
 * Renders a toastr
 * @param {Object} options
 *  .type {String} optional
 *  .message {String} optional
 *  .title {String} optional
 *  .toastrOptions {Object} optional
 */
const render = (options) => {
    const type = options.type || _defaultType;
    const message = options.message || _defaultMessage;
    const title = options.title || null;
    const toastrOptions = options.toastrOptions || _toastrOptions;
    toastr[type](message, title, toastrOptions);
};

/**
 * Removes toastrs with animation
 */
const clear = () => {
    toastr.clear();
};

/**
 * Removes toastrs without animation
 */
const remove = () => {
    toastr.remove();
};

/**
 * Exposes the toastr object itself so you can use it
 */
const getToastrInstance = () => {
    return toastr;
};

module.exports = {
    config: config,
    setToastrOptions: setToastrOptions,
    render: render,
    clear: clear,
    remove: remove,
    getToastrInstance: getToastrInstance
};