import { getItem } from '../helpers/object-helper';
import { floatStringFormat } from '../helpers/currency-helper';

const googleMaps = window.google.maps;
const confUrlBasePathname = process.env.PUBLIC_URL;

const confData = {
    // web api
    api: {
        development: {
            baseUrl: 'http://localhost:3001',
            //baseUrl: 'https://acer.amtek.site/solarconfigtest'
        },
        production: {
            baseUrl: 'https://acer.amtek.site/solarconfig'          // ENVIRONMENT: PRODUCTION
            // baseUrl: 'https://acer.amtek.site/solarconfigtest'   // ENVIRONMENT: TEST
        }
    },
    // gui data
    gui: {
        loading: {
            label: 'Stiamo elaborando la tua richiesta'
        },
        docs: {
            basePath: (process.env.NODE_ENV === 'development') ? '/docs' : `${ confUrlBasePathname }/docs`
        },
        map: {
            _k: 'AIzaSyDaJLwsUmFr05RY1WbrzQ-V8suIicSFzIg',
            imageGeneratorUrl: 'https://maps.googleapis.com/maps/api/staticmap',
            address: 'Piazza Duomo, Milano',
            addressMarkerIcon: {
                path: googleMaps.SymbolPath.BACKWARD_CLOSED_ARROW,
                fillColor: '#f7bd01',
                fillOpacity: 1,
                strokeColor: 'white',
                strokeWeight: 1,
                scale: 4,
                rotation: 30
            },
            mapOptions: {
                zoom: 18,
                mapTypeId: googleMaps.MapTypeId.SATELLITE
            },
            drawingManagerOptions: {},
            polygonOptions: {
                strokeWeight: 0,
                fillOpacity: 0.75,
                fillColor: '#f7bd01',
                editable: true,
                draggable: true
            },
            mapStaticImage: {
                generatorUrl: 'https://maps.googleapis.com/maps/api/staticmap',
                size: '400x400',
                sizeInQuotationsComponent: '463x154',//'463x204',//'331x204',
                scale: 1
            }
        },
        steps: {
            step1: {
                title: 'Seleziona la superficie totale del tuo tetto',
                nextValidationFailMessage: 'E\' necessario definire una superficie del tetto compresa fra 8 mq e 500 mq',
                map: {
                    address: 'Piazza Duomo, Milano',
                    mapOptions: {
                        zoom: 18,
                        mapTypeId: googleMaps.MapTypeId.SATELLITE
                    },
                    drawingManagerOptions: {},
                    polygonOptions: {}
                }
            },
            step2: {
                title: 'Seleziona l\'orientamento prevalente del tuo tetto',
                nextValidationFailMessage: 'E\' necessario selezionare l\'orientamento del tetto'
            },
            step3: {
                title: 'Seleziona l\'inclinazione del tuo tetto',
                nextValidationFailMessage: 'E\' necessario selezionare l\'inclinazione del tetto'
            },
            /*
            step4: {
                title: 'Seleziona la tipologia del tetto',
                nextValidationFailMessage: 'E\' necessario selezionare la tipologia del tetto'
            },
            step5: {
                title: 'Seleziona la tipologia di copertura del tuo tetto',
                nextValidationFailMessage: 'E\' necessario selezionare la tipologia di copertura del tetto'
            },
            */
            step4: {
                title: 'Seleziona il numero di piani fuori terra della tua casa',
                nextValidationFailMessage: 'E\' necessario selezionare il numero di piani fuori terra della casa'
            },
            step5: {
                title: 'Quante persone vivono in casa?',
                nextValidationFailMessage: 'E\' necessario indicare il consumo di energia elettrica'
            },
            /*
            step6: {
                title: 'Quanto viene utilizzata la tua casa nei giorni feriali?',
                nextValidationFailMessage: 'E\' necessario fornire una risposta'
            }
            step7: {
                title: 'Hai intenzione di ricaricare un\' auto elettrica a casa?',
                nextValidationFailMessage: 'E\' necessario fornire una risposta'
            }
            */
        },
        quotations: {
            title: 'Scegli la soluzione più adatta a te'
        },
        configurationState: {
            user: {
                exclude: ['pv', 'city', 'postalCode']
            },
            configuration: {
                exclude: [
                    'roofTipology',
                    'roofMaterial',
                    'electricityConsumptionPersone',
                    'electricityConsumptionCost',
                    'houseUsage',
                    'electricCar'
                ]
            }
        }
    },
    // user data
    user: {
        address: {
            label: 'Indirizzo',
            webServiceParamName: 'indirizzo'
        },
        pv: {
            label: 'Provincia'
        },
        city: {
            label: 'Città'
        },
        postalCode: {
            label: 'Codice postale'
        },
        email: {
            label: 'Email'
        }
    },
    // solar system configuration data (steps filled by the user)
    configuration: {
        roofArea: {
            label: 'Superficie',
            measureUnit: 'mq',                                                  // optional
            webServiceParamName: 'area'                                         // optional
            //webServiceValueFn:                                                // optional
            //valueProcessFn: (value) => value*value                            // optional, processes value
            //valueGuiFn: (value) => value + ' for gui'                         // optional, gets value's gui representation
            //valueGuiForConfigurationStateFn: (value) => value + ' for gui'    // optional, gets value's gui representation for ConfiguratinState Component
        },
        roofOrientation: {
            label: 'Orientamento del tetto',
            webServiceParamName: 'orientamento',
            webServiceValueFn: (value) => {
                const map = {
                    N: 'N',
                    NE: 'NE',
                    E: 'E',
                    SE: 'SE',
                    S: 'S',
                    SW: 'SO',
                    W: 'O',
                    NW: 'NO'
                }
                return map[value];
            },
            valueGuiFn: (value) => {
                const map = {
                    N: 'Nord',
                    NE: 'Nord-Est',
                    E: 'Est',
                    SE: 'Sud-Est',
                    S: 'Sud',
                    SW: 'Sud-Ovest',
                    W: 'Ovest',
                    NW: 'Nord-Ovest'
                }
                return map[value];
            }
        },
        roofInclination:  {
            label: 'Inclinazione del tetto',
            webServiceParamName: 'inclinazione',
            webServiceValueFn: (value) => {
                const map = {
                    '0': 0,
                    '20': 20,
                    '40': 40
                }
                return map[value];
            },
            valueGuiFn: (value) => {
                const map = {
                    '0': 'Piatto',
                    '20': 'Standard',
                    '40': 'Montagna'
                }
                return map[value];
            }
        },
        roofTipology: {
            label: 'Tipologia del tetto',
            webServiceParamName: 'tipologia',
            webServiceValueFn: (value) => {
                const map = {
                    '1falda': 1,
                    '2falde': 2,
                    '4falde': 4,
                    'complesso': 0
                }
                return map[value];
            },
            valueGuiFn: (value) => {
                const map = {
                    '1falda': '1 Falda',
                    '2falde': '2 Falde',
                    '4falde': '4 Falde',
                    'complesso': 'Complesso'
                }
                return map[value];
            }
        },
        roofMaterial: {
            label: 'Tipo di copertura',
            webServiceParamName: 'copertura',
            valueGuiFn: (value) => {
                const map = {
                    'Tegola': 'Tegola',
                    'Coppi': 'Coppi',
                    'GuainaBituminosa': 'Guaina Bituminosa',
                    'Lamiera': 'Lamiera',
                    'Pietra': 'Pietra',
                    'NonSo': 'Non so'
                }
                return map[value];
            }
        },
        floorsNumber: {
            label: 'Numero di piani',
            webServiceParamName: 'piani',
            webServiceValueFn: (value) => {
                const map = {
                    '1piano': 1,
                    '2piani': 2,
                    '3piani': 3,
                    '4piani': 4
                }
                return map[value];
            },
            valueGuiFn: (value) => {
                const map = {
                    '1piano': '1 Piano',
                    '2piani': '2 Piani',
                    '3piani': '3 Piani',
                    '4piani': '4 + Piani'
                }
                return map[value];
            }
        },
        electricityConsumption: {
            label: 'Consumo di energia',
            measureUnit: 'kWh/anno',  
            webServiceParamName: 'fabbisogno',
            valueGuiFn: (value) => {
                const map = {
                    '1persona': '1 persona',
                    '2persone': '2 persone',
                    '3persone': '3 persone',
                    '4persone': '4 persone',
                    'Plus4persone': '4+ persone'
                }
                return map[value];
            },
            valueGuiForConfigurationStateFn: (value) => {
                return floatStringFormat(value.toString());
            }
        },
        houseUsage: {
            label: 'Utilizzo della casa',
            webServiceParamName: 'utilizzo',
            webServiceValueFn: (value) => {
                const map = {
                    'Vuota24': 0,
                    'Occupata24': 1,
                    'OccupataMattina': 2,
                    'OccupataPost18': 3
                }
                return map[value];
            },
            valueGuiFn: (value) => {
                const map = {
                    'Vuota24': 'Vuota tutto il giorno',
                    'Occupata24': 'Occupata tutto il giorno',
                    'OccupataMattina': 'Occupata la mattina',
                    'OccupataPost18': 'Occupata dopo le 18:00'
                }
                return map[value];
            }
        },
        electricCar: {
            label: 'Auto elettrica',
            webServiceParamName: 'auto',
            webServiceValueFn: (value) => {
                const map = {
                    'Si': 'SI',
                    'No': 'NO'
                }
                return map[value];
            },
        }
    },
    altquotations: {
        identificationDataIds: ['potenza', 'capacitaSistemaAccumulo'],
        featureNotAvailableValue: 'Non disponibile'
    }
};

/**
 * Returns the application's url base path
 * @returns {String}
 */
 const getConfUrlBasePathName = () => confUrlBasePathname;

/**
 * Returns a configuration item
 * @param {String} itemPath
 * @param {Object} object
 * @return {Mixed}
 * 
 * @usage
 *  const item = getConfItem('configuration');
 *  const item = getConfItem('configuration.roofArea.label'); 
 */
const getConfItem = (itemPath, object = confData) => {
    return getItem(itemPath, object);
};

const getLabel = (itemPath, defaultLabel = '') => {
    return getConfItem(itemPath + '.label') || defaultLabel;
};

const getMeasureUnit = (itemPath, defaultMeasureUnit = '') => {
    return getConfItem(itemPath + '.measureUnit') || defaultMeasureUnit;
};

const getValueProcessFn = (itemPath) => {
    return getConfItem(itemPath + '.valueProcessFn');
};

const getValueGuiFn = (itemPath) => {
    return getConfItem(itemPath + '.valueGuiFn');
};

const getValueGuiForConfigurationStateFn = (itemPath) => {
    return getConfItem(itemPath + '.valueGuiForConfigurationStateFn');
};

const getWebServiceParamName = (itemPath) => {
    return getConfItem(itemPath + '.webServiceParamName');
};

const getWebServiceValueFn = (itemPath) => {
    return getConfItem(itemPath + '.webServiceValueFn');
};

const getStepTitle = (step) => {
    return getConfItem('gui.steps.step' + step + '.title');
};

const getQuotationsTitle = () => {
    return getConfItem('gui.quotations.title');
};

export default confData;
export {
    getConfUrlBasePathName,
    getConfItem,
    getLabel,
    getMeasureUnit,
    getValueProcessFn,
    getValueGuiFn,
    getValueGuiForConfigurationStateFn,
    getWebServiceParamName,
    getWebServiceValueFn,
    getStepTitle,
    getQuotationsTitle
};