import {
    STORE_RESET,
    APPLICATION_CONTEXT_UPDATE,
    APPLICATION_CONTEXT_UPDATE_CHANNEL,
    APPLICATION_CONTEXT_UPDATE_SOLARSPECIALIST,
    APPLICATION_CONTEXT_UPDATE_CAMPAIGN,
    GUI_UPDATE,
    GUI_ONPOPSTATE_LISTENER_ADD,
    GUI_WIZARD_NEXTAUTOSTEP_WATCHER_ADD,
    GUI_WIZARD_BUTTON_NAV_CLICKED,
    USER_UPDATE,
    CONFIGURATION_UPDATE,
    MAP_UPDATE,
    MAP_STATIC_IMAGE_URL_UPDATE,
    QUOTATIONS_UPDATE,
    QUOTATIONS_UPDATE_SNAPSHOT,
    QUOTATIONS_UPDATE_UUID,
    QUOTATIONS_UPDATE_TOTAL,
    QUOTATIONS_UPDATE_OPTION,
    QUOTATIONS_UPDATE_SELECTED_QUOTATION,
    QUOTATIONS_UPDATE_SELECTED_IMPIANTO,
    QUOTATIONS_FORM_UPDATE_FIELD,
    BATTERIES_UPDATE,
    BESTQUOTATIONS_UPDATE,
    ALTQUOTATIONS_UPDATE
} from './action-types';

/**
 * Returns a APPLICATION_CONTEXT_UPDATE action
 * @param {Object|undefined} payload
 *  No payload needed at the moment
 */
export function storeReset(payload) {
    return {
        type: STORE_RESET,
        payload: payload
    };
};

/**
 * Returns a APPLICATION_CONTEXT_UPDATE action
 * @param {Object} payload 
 *  .ch
 *  .ss
 *  .cmp
 */
export function updateApplicationContext(payload) {
    return {
        type: APPLICATION_CONTEXT_UPDATE,
        payload: payload
    };
};

/**
 * Returns a APPLICATION_CONTEXT_UPDATE_CHANNEL action
 * @param {Object} payload 
 *  .ch
 */
export function updateApplicationContextChannel(payload) {
    return {
        type: APPLICATION_CONTEXT_UPDATE_CHANNEL,
        payload: payload
    };
};

/**
 * Returns a APPLICATION_CONTEXT_UPDATE_SOLARSPECIALIST action
 * @param {Object} payload 
 *  .ss
 */
export function updateApplicationContextSolarSpecialist(payload) {
    return {
        type: APPLICATION_CONTEXT_UPDATE_SOLARSPECIALIST,
        payload: payload
    };
};

/**
 * Returns a APPLICATION_CONTEXT_UPDATE_SOLARSPECIALIST action
 * @param {Object} payload 
 *  .cmp
 */
export function updateApplicationContextCampaign(payload) {
    return {
        type: APPLICATION_CONTEXT_UPDATE_CAMPAIGN,
        payload: payload
    };
};

/**
 * Returns a GUI_UPDATE action
 * @param {Object} payload 
 *  ...any props belonging to appReducer's state.gui object
 */
export function updateGui(payload) {
    return {
        type: GUI_UPDATE,
        payload: payload
    };
};

/**
 * Returns a GUI_ONPOPSTATE_LISTENER_ADD action
 * @param {Object} payload
 *  .listenerId {Boolean}
 */
export function guiOnpopstateListenerAdd(payload) {
    return {
        type: GUI_ONPOPSTATE_LISTENER_ADD,
        payload: payload
    };
};

/**
 * Returns a GUI_WIZARD_NEXTAUTOSTEP_WATCHER_ADD action
 * @param {Object} payload
 *  .listenerId {Boolean}
 */
export function guiWizardNextautostepWatcherAdd(payload) {
    return {
        type: GUI_WIZARD_NEXTAUTOSTEP_WATCHER_ADD,
        payload: payload
    };
};

/**
 * Returns a GUI_WIZARD_BUTTON_NAV_CLICKED action
 * @param {Object} payload
 *  .clicked {Boolean}
 */
export function guiWizardButtonNavClicked(payload) {
    return {
        type: GUI_WIZARD_BUTTON_NAV_CLICKED,
        payload: payload
    };
};

/**
 * Returns a USER_UPDATE action
 * @param {Object} payload 
 *  ...any props belonging to appReducer's state.user object
 */
export function updateUser(payload) {
    return {
        type: USER_UPDATE,
        payload: payload
    };
};

/**
 * Returns a CONFIGURATION_UPDATE action
 * @param {Object} payload 
 *  ...any props belonging to appReducer's state.configuration object
 */
export function updateConfiguration(payload) {
    return {
        type: CONFIGURATION_UPDATE,
        payload: payload
    };
};

/**
 * Returns a MAP_UPDATE action
 * @param {Object} payload 
 *  ...any props belonging to appReducer's state.map object
 */
export function updateMap(payload) {
    return {
        type: MAP_UPDATE,
        payload: payload
    };
};

/**
 * Returns a MAP_STATIC_IMAGE_URL_UPDATE action
 * @param {Object} payload
 *  .url
 */
export function updateMapStaticImageUrl(payload) {
    return {
        type: MAP_STATIC_IMAGE_URL_UPDATE,
        payload: payload
    };
};

/**
 * Returns a QUOTATIONS_UPDATE action
 * @param {Object} payload
 *  .quotations
 */
export function updateQuotations(payload) {
    return {
        type: QUOTATIONS_UPDATE,
        payload: payload
    };
};

/**
 * Returns a QUOTATIONS_UPDATE_SNAPSHOT action
 * @param {Object} payload
 *  .snapshot {Object}
 */
export function updateQuotationsSnapshot(payload) {
    return {
        type: QUOTATIONS_UPDATE_SNAPSHOT,
        payload: payload
    };
};

/**
 * Returns a QUOTATIONS_UPDATE_UUID action
 * @param {Object} payload
 *  .uuid {String}
 */
export function updateQuotationsUuid(payload) {
    return {
        type: QUOTATIONS_UPDATE_UUID,
        payload: payload
    };
};

/**
 * Returns a QUOTATIONS_UPDATE_TOTAL action
 * @param {Object} payload
 *  .quotationType
 *  .totalValue
 */
export function updateQuotationsTotal(payload) {
    return {
        type: QUOTATIONS_UPDATE_TOTAL,
        payload: payload
    };
};

/**
 * Returns a QUOTATIONS_UPDATE_OPTION action
 * @param {Object} payload
 *  .quotationType
 *  .optionId
 *  .optionValue
 */
export function updateQuotationsOption(payload) {
    return {
        type: QUOTATIONS_UPDATE_OPTION,
        payload: payload
    };
};

/**
 * Returns a QUOTATIONS_UPDATE_SELECTED_QUOTATION action
 * @param {Object} payload
 *  .quotationType
 */
export function updateQuotationsSelectedQuotation(payload) {
    return {
        type: QUOTATIONS_UPDATE_SELECTED_QUOTATION,
        payload: payload
    };
};

/**
 * Returns a QUOTATIONS_UPDATE_SELECTED_IMPIANTO action
 * @param {Object} payload
 *  .impianto
 */
export function updateQuotationsSelectedImpianto(payload) {
    return {
        type: QUOTATIONS_UPDATE_SELECTED_IMPIANTO,
        payload: payload
    };
};

/**
 * Returns a QUOTATIONS_FORM_UPDATE_FIELD action
 * @param {Object} payload
 *  ...any props belonging to appReducer's state.quotationsForm object
 */
export function updateQuotationsFormField(payload) {
    return {
        type: QUOTATIONS_FORM_UPDATE_FIELD,
        payload: payload
    };
};

/**
 * Returns a BATTERIES_UPDATE action
 * @param {Object} payload
 *  .batteries {Object[]}
 *      See mock-data/quote-service-response.json->batteries
 *      [n]
 *          .code {String}
 *          .label {String}
 */
export function updateBatteries(payload) {
    return {
        type: BATTERIES_UPDATE,
        payload: payload
    };
};

/**
 * Returns a BESTQUOTATIONS_UPDATE action
 * @param {Object} payload
 *  .bestquotations {Object}
 *      See mock-data/quote-service-response.json->quotations
 */
export function updateBestquotations(payload) {
    return {
        type: BESTQUOTATIONS_UPDATE,
        payload: payload
    };
};

/**
 * Returns a ALTQUOTATIONS_UPDATE action
 * @param {Object} payload
 *  .altquotations {Object}
 *      See mock-data/quote-service-response.json->altquotations
 */
export function updateAltquotations(payload) {
    return {
        type: ALTQUOTATIONS_UPDATE,
        payload: payload
    };
};