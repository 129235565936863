export const STORE_RESET = 'STORE_RESET';
export const APPLICATION_CONTEXT_UPDATE = 'APPLICATION_CONTEXT_UPDATE';
export const APPLICATION_CONTEXT_UPDATE_CHANNEL = 'APPLICATION_CONTEXT_UPDATE_CHANNEL';
export const APPLICATION_CONTEXT_UPDATE_SOLARSPECIALIST = 'APPLICATION_CONTEXT_UPDATE_SOLARSPECIALIST';
export const APPLICATION_CONTEXT_UPDATE_CAMPAIGN = 'APPLICATION_CONTEXT_UPDATE_CAMPAIGN';
export const GUI_UPDATE = 'GUI_UPDATE';
export const GUI_ONPOPSTATE_LISTENER_ADD = 'GUI_ONPOPSTATE_LISTENER_ADD';
export const GUI_WIZARD_NEXTAUTOSTEP_WATCHER_ADD = 'GUI_WIZARD_NEXTAUTOSTEP_WATCHER_ADD';
export const GUI_WIZARD_BUTTON_NAV_CLICKED = 'GUI_WIZARD_BUTTON_NAV_CLICKED';
export const USER_UPDATE = 'USER_UPDATE';
export const CONFIGURATION_UPDATE = 'CONFIGURATION_UPDATE';
export const MAP_UPDATE = 'MAP_UPDATE';
export const MAP_STATIC_IMAGE_URL_UPDATE = 'MAP_STATIC_IMAGE_URL_UPDATE';
export const QUOTATIONS_UPDATE = 'QUOTATIONS_UPDATE';
export const QUOTATIONS_UPDATE_SNAPSHOT = 'QUOTATIONS_UPDATE_SNAPSHOT';
export const QUOTATIONS_UPDATE_UUID = 'QUOTATIONS_UPDATE_UUID';
export const QUOTATIONS_UPDATE_TOTAL = 'QUOTATIONS_UPDATE_TOTAL';
export const QUOTATIONS_UPDATE_OPTION = 'QUOTATIONS_UPDATE_OPTION';
export const QUOTATIONS_UPDATE_SELECTED_QUOTATION = 'QUOTATIONS_UPDATE_SELECTED_QUOTATION';
export const QUOTATIONS_UPDATE_SELECTED_IMPIANTO = 'QUOTATIONS_UPDATE_SELECTED_IMPIANTO';
export const QUOTATIONS_FORM_UPDATE_FIELD = 'QUOTATIONS_FORM_UPDATE_FIELD';
export const BATTERIES_UPDATE = 'BATTERIES_UPDATE';
export const BESTQUOTATIONS_UPDATE = 'BESTQUOTATIONS_UPDATE';
export const ALTQUOTATIONS_UPDATE = 'ALTQUOTATIONS_UPDATE';