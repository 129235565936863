/**
 * PageWizard Components
 * 
 * @state
 *      responsive
 */

import React, { useState } from 'react';

import appStore from '../../redux/store/appStore';

import Header from '../Header';
import Wizard from '../wizard/Wizard';
import WizardInfos from '../wizard/infos/WizardInfos';
import ConfigurationState from '../ConfigurationState';
import Footer from '../Footer';

import { getItem } from '../../helpers/object-helper';
import { pageComponentCssClass } from '../../helpers/components-helper';

import './Page.css';
import './PageWizard.css';

const PageWizard = (props) => {

    /**
     * Reads gui.responsive from store
     */
    const responsiveFromStore = function() {
        return getItem('gui.responsive', appStore.getState());
    };

    const [responsive, setResponsive] = useState(responsiveFromStore());

    /**
     * updates responsive on window resize
     */
    const reponsiveUpdater = () => {
        window.addEventListener('resize', function() {
            setResponsive(responsiveFromStore());
        });
    };
    reponsiveUpdater();

    /**
     * Returns the page css class name
     * @return {String}
     *  Sample
     *      'slc-page slc-page-home responsive-desktop
     */
    const pageClassName = function() {
        return pageComponentCssClass('wizard', responsive);
    };

    const openInfos = function() {
        document.querySelector('.slc-page-wizard-infos').classList.add('slc-page-wizard-infos--opened');
        document.querySelector('.slc-wizard-infos-btn-close').style.display = 'flex';
    };

    const closeInfos = function() {
        document.querySelector('.slc-page-wizard-infos').classList.remove('slc-page-wizard-infos--opened');
        document.querySelector('.slc-wizard-infos-btn-close').style.display = 'none';
    };

    return (
        <div className={pageClassName()}>
            <div className="row slc-page-header">
                <div className="col s12">
                    <Header />
                </div>
            </div>
            <div className="row slc-page-body">
                <div className="col s12 l3 slc-page-wizard-infos">
                    <WizardInfos openInfosFn={openInfos} closeInfosFn={closeInfos} />
                </div>
                <div className="col s12 l6 slc-page-wizard-wizard">
                    <Wizard />
                </div>
                <div className="col s12 l3 slc-page-wizard-configuration-state">
                    <ConfigurationState />
                </div>
            </div>
            <div className="slc-page-footer">
                <Footer />
            </div>
        </div>
    );

};

export default PageWizard;
