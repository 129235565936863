/**
 * Quotations Form Helper module
 * Provides tipical form specific logic, mostly made of dom related functionalities
 * This is a kind of an interface
 * The dom related functionalities are most likely to be changed project by project
 * ...because they are deeply connected the html implementation
 * 
 * @specification config {Object}
 *  .fields {Array}
 *      [n]
 *          .id {String}
 *          .domElement {HTMLElement}
 *              The input element itself
 *          .domElementContainer {HTMLElement} optional
 *              The input element container, if it exists in the HTML implementation
 *          .getValue {Function} optional
 *              Returns the field value
 *              Used mostly when the field to be validated is not a form dom field, but a data value of some sort (application state, for example)
 *          .validate {RegExp|Function} optional
 *              The RegExp or Function used to validate the field
 *              When Function, the function signature is:
 *                  validate(fieldId, fieldValue)
 *          .domGuiFieldValid {Function} optional
 *              Renders the gui valid state for the field
 *              If not defined, the more general formHelper.domGuiFieldValid Function is used
 *          .domGuiFieldInvalid {Function} optional
 *              Renders the gui invalid state for the field
 *              If not defined, the more general formHelper.domGuiFieldInvalid Function is used
 *          .validationErrorMsg {String} optional
 */

import appStore from '../redux/store/appStore';
import { getItem } from '../helpers/object-helper';

const config = {
    submitBtn: {
        domElement: () => document.getElementById('submit'),
    },
    fields: [
        {
            id: 'nome',
            domElement: () => document.querySelector('.slc-form-input-text-widget__input-nome'),
            domElementContainer: () => document.getElementById('nome'),
            validate: /[\w\d ]{2,}/,
            validationErrorMsg: 'Deve essere di almeno 2 caratteri',
        },
        {
            id: 'cognome',
            domElement: () => document.querySelector('.slc-form-input-text-widget__input-cognome'),
            domElementContainer: () => document.getElementById('cognome'),
            validate: /[\w\d ]{2,}/,
            validationErrorMsg: 'Deve essere di almeno 2 caratteri',
        },
        {
            id: 'indirizzo',
            domElement: () => document.querySelector('.slc-form-input-text-widget__input-indirizzo'),
            domElementContainer: () => document.getElementById('indirizzo'),
            validate: /[\w\d' ]{2,}/,
            validationErrorMsg: 'Deve essere di almeno 2 caratteri',
        },
        {
            id: 'ragionesociale',
            domElement: () => document.querySelector('.slc-form-input-text-widget__input-ragionesociale'),
            domElementContainer: () => document.getElementById('ragionesociale'),
            //validate: /[\w\d ]{2,}/,
            //validationErrorMsg: 'Deve essere di almeno 2 caratteri',
        },
        {
            id: 'email',
            domElement: () => document.querySelector('.slc-form-input-text-widget__input-email'),
            domElementContainer: () => document.getElementById('email'),
            validate: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            validationErrorMsg: 'Deve essere una email valida',
            required: true
        },
        {
            id: 'telefono',
            domElement: () => document.querySelector('.slc-form-input-text-widget__input-telefono'),
            domElementContainer: () => document.getElementById('telefono'),
            validate: /[+\d ]{5,}/,
            validationErrorMsg: 'Deve essere un numero di telefono valido',
        },
        {
            id: 'consensoAuthorizeA',
            domElement: () => document.querySelector('.slc-form-input-checkbox-widget__input-consensoAuthorizeA'),
            domElementContainer: () => document.getElementById('consensoAuthorizeA'),
            // validate: (fieldId, fieldValue) => {
            //     return (fieldValue === true);
            // },
            // validationErrorMsg: 'E\' necessario accettare',
        },
        {
            id: 'consensoAuthorizeB',
            domElement: () => document.querySelector('.slc-form-input-checkbox-widget__input-consensoAuthorizeB'),
            domElementContainer: () => document.getElementById('consensoAuthorizeB'),
            // validate: (fieldId, fieldValue) => {
            //     return (fieldValue === true);
            // },
            // validationErrorMsg: 'E\' necessario accettare',
        },
        {
            id: 'consensoAuthorizeC',
            domElement: () => document.querySelector('.slc-form-input-checkbox-widget__input-consensoAuthorizeC'),
            domElementContainer: () => document.getElementById('consensoAuthorizeC'),
            // validate: (fieldId, fieldValue) => {
            //     return (fieldValue === true);
            // },
            // validationErrorMsg: 'E\' necessario accettare',
        },
        // this is not a form field but a store value appStore.getState().quotations.selectedImpianto
        {
            id: 'selectedImpianto',
            domElement: () => null,
            domElementContainer: () => document.querySelector('.slc-data-scegli'),
            getValue: () => {
                const selectedImpianto = getItem('quotations.selectedImpianto', appStore.getState());
                return selectedImpianto;
            },
            validate: (fieldId, fieldValue) => {
                return (fieldValue !== undefined && fieldValue !== null);
            },
            domGuiFieldValid: (fieldId, $fieldDomElement, $fieldDomElementContainer) => {
                $fieldDomElementContainer.classList.remove('slc-form-item-validation-error');
            },
            domGuiFieldInvalid: (fieldId, $fieldDomElement, $fieldDomElementContainer) => {
                $fieldDomElementContainer.classList.add('slc-form-item-validation-error');
            },
            validationErrorMsg: 'E\' necessario scegliere un impianto',
            required: true
        }
        
    ]
};

const formHelper = {

    data: {
        formValid: null
    },

    /**
     * Starts the form
     *  1. Validates the form
     *  2. Set the data.formValid state
     *  3. Calls the submit btn state manager 
     */
    formStart: (formConfig) => {
        const validationResult = formHelper.formValidate(formConfig);
        formHelper.data.formValid = validationResult.valid;
        formHelper.submitBtnManageState(formConfig);
    },

    /**
     * Validates the form
     * Validation is drvien by the fields configured to be validated (formConfig.fields)
     * @returns {Object}
     *  .valid {Boolean}
     *  .invalidFields {Object}
     *      .<fieldId> {String|false}
     */
    formValidate: (formConfig) => {
        let valid = true;
        let invalidFields = {};
        const fields = formConfig.fields;
        let fieldId,
            $field,
            fieldValid;
        for (let fieldConfig of fields) {
            fieldId = fieldConfig.id;
            $field = fieldConfig.domElement();
            fieldValid = formHelper.fieldValidate($field, fieldConfig);
            if (!fieldValid) {
                valid = false;
                invalidFields[fieldId] = (fieldConfig.validationErrorMsg) ?
                    fieldConfig.validationErrorMsg :
                    false;
            }
        }
        return {
            valid: valid,
            invalidFields: invalidFields
        };
    },

    /**
     * Returns the form btn config
     * @return {Object}
     */
    submitBtnGetConfig: (formConfig) => {
        return formConfig.submitBtn;
    },

    /**
     * Returns the field config
     * Assumes config.fields is provided in the format documented in @specification config {Object}
     * @param {String|HTMLElement} field
     * @param {Object} formConfig
     * @returns {Object}
     */
    fieldGetConfig: (field, formConfig) => {
        const fieldId = formHelper.fieldResolveId(field);
        const fields = formConfig.fields;
        return fields
            .filter((field) => {
                if (field.id === fieldId) {
                    return field;
                }
            })
            .reduce((acc, field) => {
                return field;
            }, null);
    },

    /**
     * Validates field value against fieldConfig
     * If no fieldConfig exists, $field is validated straight away
     * @param {HTMLElement} $field
     * @param {Object|null} fieldConfig
     * @returns {Boolean}
     */
    fieldValidate: ($field, fieldConfig) => {

        // validate if not fieldConfig
        if (!fieldConfig) {
            return true;
        }

        // assume valid by default
        let valid = true;

        // set useful vars
        const fieldId = fieldConfig.id;
        const fieldValue = (fieldConfig.getValue) ? fieldConfig.getValue() : formHelper.fieldGetValue($field);
        //const fieldValue = formHelper.fieldGetValue($field);

        // validate if some validation is defined in fieldConfig
        if (fieldConfig.validate) {
            // validation by Function
            if (typeof(fieldConfig.validate) === 'function') {
                valid = fieldConfig.validate(fieldId, fieldValue);
            // validation by RegExp
            } else {
                valid = fieldConfig.validate.test(fieldValue);
            }
        }

        // return
        return valid;
    },

    /**
     * Returns $field's value
     * Value is computed in different ways, dependign on $field's type (text, checkbox, radio and so on)
     * Only the following cases are handled at the moment:
     *  - text
     *  - checkbox
     * @param {HTMLElement} $field
     * @returns {String}
     */
    fieldGetValue: ($field) => {
        let value = null;
        switch ($field.type) {
            case 'text':
                value = $field.value;
                break;
            case 'checkbox':
                value = $field.checked;
                break;
            default:
                value = $field.value;
        }
        return value;
    },

    /**
     * Returns the field's fieldId
     * @param {String|HTMLElement} field
     *  If String, it is mirrored returned
     *  If HMTLElement, the element id is returned by the domFieldGetId($field) function
     * @returns {String}
     */
    fieldResolveId: (field) => {
        return (typeof(field) === 'string') ? field : formHelper.domFieldGetId(field);
    },

    /**
     * Manages the submit btn state
     * The state is driven by form validation
     *  a) If form is valid
     *      formHelper.data.formValid flag is set to true
     *      the btn gui is assigned the valid view
     *  b) If form is not valid
     *      formHelper.data.formValid flag is set to false
     *      the btn gui is assigned the invalid view
     */
    submitBtnManageState: (formConfig) => {
        const formValid = formHelper.formValidate(formConfig);
        const $submitBtn = formHelper.domGetSubmitBtn(formConfig);
        if (formValid.valid) {
            formHelper.data.formValid = true;
            formHelper.domGuiSubmitBtnValid($submitBtn);
        } else {
            formHelper.data.formValid = false;
            formHelper.domGuiSubmitBtnInvalid($submitBtn);
        }
    },

    /**
     * Renders gui invalid view for invalidFields
     * @param {Object} invalidFields
     *  .<fieldId> = <invalid field message>
     * @param {Object} formConfig
     */
    domGuiFieldsInvalid: (invalidFields, formConfig) => {
        let fieldId,
            $field;
        for (fieldId in invalidFields) {
            /** OLD
            $field = formHelper.domGetFieldById(fieldId, formConfig);
                formHelper.domGuiFieldInvalid($field);
             */
            const fieldConfig = formHelper.fieldGetConfig(fieldId, formConfig);
            // use field specific domGuiFieldInvalid function if defined
            if (fieldConfig.domGuiFieldInvalid) {
                fieldConfig.domGuiFieldInvalid(fieldId, fieldConfig.domElement(), fieldConfig.domElementContainer());
            // use form domGuiFieldInvalid if no field specific function is defined
            } else {
                $field = formHelper.domGetFieldById(fieldId, formConfig);
                formHelper.domGuiFieldInvalid($field);
            }

        }
    },

    /**
     * Returns the submit btn HTMLElement
     * @returns {HTMLElement}
     */
    domGetSubmitBtn: (formConfig) => {
        return formConfig.submitBtn.domElement();
    },

    /**
     * Returns the fieldId field HTMLElement
     * @param {String} fieldId
     * @param {Object} formConfig
     * @returns {HTMLElement}
     */
    domGetFieldById: (fieldId, formConfig) => {
        return formHelper.fieldGetConfig(fieldId, formConfig).domElement();
    },

    /**                                                  Project specific implementation
     * =================================================================================
     */

    /**
     * Returns the id of $field, reading it from the dom
     * This is dependent on how the html/css has been implemented
     * @returns {String}
     */
    domFieldGetId: ($field) => {
        let parentElement = ($field.nodeName === 'INPUT' || $field.nodeName === 'SELECT') ? $field.parentElement.parentElement : $field.parentElement;
        return parentElement.getAttribute('id');
    },

    /**
     * Returns the $checkbox checked value
     * @param {HTMLCheckboxElement} $checkbox 
     * @return {Boolean}
     */
    domCheckboxGetValue($checkbox) {
        return $checkbox.checked;
    },

    domGuiFieldInvalid: ($field) => {
        let $target;
        switch ($field.type) {
            case 'checkbox':
                    $target = $field.parentElement;
                break;
            default:
                    $target = $field;
        }
        $target.classList.add('slc-form-item-validation-error');
    },

    domGuiFieldValid: ($field) => {
        let $target;
        switch ($field.type) {
            case 'checkbox':
                    $target = $field.parentElement;
                break;
            default:
                    $target = $field;
        }
        $target.classList.remove('slc-form-item-validation-error');
    },

    domGuiSubmitBtnValid: ($submitBtn) => {
        $submitBtn.classList.remove('slc-form-submitBtn-disabled');
    },

    domGuiSubmitBtnInvalid: ($submitBtn) => {
        $submitBtn.classList.add('slc-form-submitBtn-disabled');
    },
};

export {
    config
};
export default formHelper;