import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

const useNavPath = (path = null) => {

    // navPath state
    const [navPath, setNavPath] = useState(path);

    // navPath navigation
    const navigateToNavPath = () => {
        return  <Redirect to={{pathname: navPath}} />;
    };

    return [navPath, setNavPath, navigateToNavPath];
};

export default useNavPath;