import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import {
    getConfItem,
    getLabel,
    getQuotationsTitle
} from '../../../conf/conf';

import { connect } from "react-redux";
import appStore from '../../../redux/store/appStore';
import {
    updateGui,
    updateQuotations,
    updateQuotationsSnapshot,
    updateQuotationsTotal,
    updateQuotationsOption,
    updateQuotationsSelectedQuotation,
    updateQuotationsSelectedImpianto,
    updateBestquotations,
    updateBatteries,
    updateAltquotations
} from '../../../redux/actions/actions';

import {
    config as httpClientConfig,
    quotationsGet as httpClientQuotationsGet
} from '../../../libs/http-client';
import { quotationsGetRequestData } from '../../../libs/http-client-dataManager';

import Header from '../../Header';
import Loading from '../../Loading';
import PageQuotationsDestkop from './PageQuotationsDesktop';

import { gaTrackPage } from '../../../helpers/google-analytics-helper';
import {
    urlRewriteWithSearchStringFromState,
    currentUrlAddSearchString
} from '../../../helpers/browser-navigation-helper';
import { mapImageUrlInReactComponent } from '../../../helpers/google-map-helper';
import { pageComponentCssClass } from '../../../helpers/components-helper';
import {
    getImportoDetrazioneBySelection,
    readQuotationFromReduxStateByInputsSnapshot
} from '../../../helpers/quotations-helper';
import {
    priceStringFormat,
    floatStringFormat,
    formatProduzioneAnnualeGarantita,
    produzioneAnnualeGarantitaMultiply
} from '../../../helpers/currency-helper';

import {
    quotationTypeFromOptionId,
    optionNameFromOptionId,
    getTotaleBySelection,
    getImpiantoBySelection,
    getOptionsSelectionState
} from '../../../helpers/quotations-helper';
import {
    getMaxPotenzaInstallabile
} from '../../../helpers/altquotations-store-helper';
import { getItem } from '../../../helpers/object-helper';

import discount10Img from '../../../images/quotations/discount10.png';

import './PageQuotations.css';

const mapStateToProps = state => {
    return {
        gui: state.gui,
        quotations: state.quotations
    };
};

const imgLabelsDummyUrl = '/images/quotations-labels-dummy.jpg';

const quotationsConfig = {
    sistemaSolare: {
        label: '',
        /**
         * Adds an hardcoded details string to each solar system label (this is a patch for the Web API /quote endpoint does not send the details information)
         * @param {String} value 
         * @param {Object} options 
         * @returns React JSX
         */
        /*
        valueGuiFn: (value, options) => {
            const details = (options.quotationType === 'basic') ?
                'Sistema Solare Solstråle Basic' :
                'Sistema Solare Solstråle Plus con Accumulo';
            return (
                <div>
                    <div>{ value }</div>
                    <div>{ details }</div>
                </div>
            );
        }
        */
    },
    /*
    tipoPannelli:{
        label:'Tipo pannelli',
        values: {
            basic: 'Monocristallino 375 Wp',
            plus: 'Monocristallino 400 Wp',
        }
    },
    */
   /*
    numeroPannelliMax: {
        label:'Massimo numero di pannelli installabili',
        values: {
            basic: '[Read from Application State]',
            plus: '[Read from Application State]',
        },
        valueGuiFn: (value, options) => {
            return getMaxNumeroPannelli(options.quotationType);
        }
    },
    */
    potenzaMaxInstallabile: {
        label:'Massima potenza installabile sul tuo tetto',
        values: {
            basic: '[Read from Application State]',
            plus: '[Read from Application State]',
        },
        valueGuiFn: (value, options) => {
            return getMaxPotenzaInstallabile(options.quotationType);
        }
    },
    /*
    numeroPannelli: {
        label:'Numero pannelli (*consigliato)',
        guiHtmlElementTypes: {
            basic: 'HtmlPlusMinus',
            plus: 'HtmlPlusMinus'
        }
    },
    */
    potenza: {
        label:'Potenza impianto fotovoltaico  <span>(*consigliata)</span>',
        guiHtmlElementTypes: {
            basic: 'HtmlPlusMinus',
            plus: 'HtmlPlusMinus'
        }
    },
    /*
    potenza: {
        label:'Potenza complessiva',
        valueGuiFn: value => floatStringFormat(value, 'kWp')
    },
    */
    produzioneAnnualeGarantita: {
        label:'Energia prodotta in un anno',
        /**
         * produzioneAnnualeGarantita value depends on ottimizzatori selection
         * @param {String} value 
         * @param {Object} options 
         *  .quotationType {String}
         *  .values {Object}
         *      .basic {String}
         *          Sample: "4.660 kWh annuo"
         *      .basicWithOttimizzatori {String}
         *      .plus {String}
         *      .plus-s {String}
         *      .plusWithOttimizzatori {String}
         *  .inputsSnapshot {Object}
         *      .accumulatoreProposto
         *          .basic {Boolean}
         *          .plus {Boolean}
         *      .ottimizzatori
         *          .plus {Boolean}
         *      .numeroPannelli
         *          .basic {Number}
         *          .plus {Number}
         *      .capacitaSistemaAccumulo
         *          .basic {String}
         *          .plus {String}
         */
        valueGuiFn: (value, options) => {
            const quotationType = options.quotationType;
            const values = options.values;
            const ottimizzatoriSelected = options.inputsSnapshot.ottimizzatori[quotationType];
            const valueKey = (ottimizzatoriSelected) ? quotationType + 'WithOttimizzatori' : quotationType; // 'basic'|'basicWithOttimizzatori'|'plus'|'plusWithOttimizzatori'
            value = values[valueKey]; // '6.000/kWh annuo'|6.300/kWh annuo
            return formatProduzioneAnnualeGarantita(value);
        },
        info: `<div class="slc-data-label-info-content__body">Il calcolo dell’energia prodotta dal tuo Sistema Solare è stato fatto in base ai dati di irraggiamento solare del Climate Saf PVGIS della Comunità Europea considerando la posizione dell’immobile (latitudine, longitudine, altitudine), la sua esposizione e la tipologia di pannelli e ottimizzatori previsti.</div>`
    },
    capacitaSistemaAccumulo:{
        label:'Sistema di Accumulo energia <span>(*consigliato)</span>',
        guiHtmlElementTypes: {
            basic: 'HtmlLabel',
            plus: 'HtmlPlusMinus'
        },
        valueGuiFn: value => (value === getConfItem('altquotations.featureNotAvailableValue') ? value : floatStringFormat(value, 'kWh')),
        info: `<div class="slc-data-label-info-content__body">Il Sistema di Accumulo è il miglior modo per rendere efficiente il tuo consumo energetico e portare la tua autonomia energetica anche all’80%.<br />Con l’accumulatore di energia la produzione energetica del tuo Sistema Solare non consumata durante le ore di irraggiamento ed in eccesso viene immagazzinata per essere rilasciata nelle ore di maggior consumo, senza necessità di prelevare energia dalla rete elettrica nazionale.</div>`
    },
    /*
    garanziaProduzione:{
        label:'Garanzia di produzione'
    },
    caratteristicheTecniche:{
        label:'Caratteristiche tecniche'
    },
    */
    /*
    accumulatoreProposto:{
        label:'Aggiungi Sistema di Accumulo',
        guiHtmlElementTypes: {
            basic: 'HtmlCheckbox',
            plus: 'HtmlCheckbox'
        }
    },
    */
    ottimizzatori:{
        label:'Aggiungi ottimizzatori di potenza',
        guiHtmlElementTypes: {
            basic: 'HtmlCheckbox',
            plus: 'HtmlCheckbox'
        },
        info: `<div class="slc-data-label-info-content__body">Gli ottimizzatori di potenza sono dispositivi montati sui pannelli fotovoltaici che permettono di ottimizzare la performance dell’intero Sistema Solare in caso di scarso o irregolare irraggiamento aumentando di circa il 5% la produzione annua di energia.<br />
        Il beneficio energetico è notevole se calcolato sull’intera vita del Sistema Solare.</div>`
    },
    // assicurazione: {
    //     label:'Assicurazione All-Risk',
    //     values: {
    //         basic: '2 anni',
    //         plus: '5 Anni',
    //     },
    //     info: `<div class="slc-data-label-info-content__body">L’assicurazione comprende indennizzi diretti ed indiretti per sinistri causati da eventi naturali, guasti tecnici, furto, rapina, atti vandalici e dolosi.</div>`
    // },
    garanziaProduzionePannelli:{
        label:'Garanzia impianto',
        values: {
            basic: '25 anni',
            plus: '25 anni'
        },
        info: `<div class="slc-data-label-info-content__body">Il buon funzionamento dei moduli fotovoltaici è garantito per 25 anni. Questo significa che i moduli produrranno almeno l'80% della loro potenza nominale dopo 25 anni.<br />I pannelli hanno anche una garanzia di 10 anni sui difetti di fabbricazione.<br />L’inverter è garantito 10 anni sui difetti di fabbricazione. Sugli ottimizzatori la garanzia è di 25 anni.<br />Le batterie hanno 10 anni di garanzia. Infine, garantiamo l'installazione a regola d’arte dell'impianto fotovoltaico per 5 anni.</div>`
    },
    risparmioInBolletta:{
        label:'Risparmio in bolletta',
        info: `<div class="slc-data-label-info-content__body">L’autonomia energetica che potrai ottenere con il consumo dell’energia autoprodotta dal tuo Sistema Solare (stima ottenuta dal rapporto del beneficio derivante da autoconsumo diretto, fonte GSE del Giugno 2019 - rapporto statistico solare fotovoltaico 2018, e del contributo in conto scambio sul posto accreditato dal GSE.<br />Tale percentuale varia inoltre in base al proprio profilo di carico in rapporto alla potenza installata dell’impianto fotovoltaico. Il dato è puramente indicativo.</div>`,
        values: {
            basic: '40%',
            plus: '80%'
        }
    },
    totale:{
        label:'Totale (IVA 10% inclusa)',
        valueGuiFn: value => <React.Fragment>
            <div className='slc-data-value-content'>
                <div className='slc-data-value-content-info'><img src={discount10Img} alt="Sconto 10%" /></div>
                {priceStringFormat(value)}
            </div>
        </React.Fragment>
    },
    importoDetrazione:{
        label:'Detrazione del 50% in 10 anni',
        info: `<div class="slc-data-label-info-content__body">La detrazione fiscale spetta al 50% in 10 anni rientrando tra gli interventi di ristrutturazione edilizia – quale intervento per il risparmio energetico – di cui all’art. 16-bis del Tuir, da usufruirsi in dieci anni entro il limite stabilito normativamente per tale tipologia di interventi e cioè 96.000 euro.</div>`,
        valueGuiFn: value => priceStringFormat(value)
    },
    scegli:{
        label: '',
        values: {
            basic: null,
            plus: null
        },
        guiHtmlElementTypes: {
            basic: 'HtmlButton',
            plus: 'HtmlButton'
        }
    }
};

class PageQuotations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            mapStaticImageUrl: null,
            loading: false,
            config: quotationsConfig,
            valutazione: null,          // quotationsResponse.valutazione
            data: null,                 // quotationsResponse.quotations
            totals: null,               // will store the totals table, cloning it quotationsResponse.quotations.totale
            importoDetrazione: null,    // will store the importoDetrazione table, cloning it quotationsResponse.quotations.importoDetrazione
        };
    }

    componentDidMount() {

        // update current url with search string
        currentUrlAddSearchString();

        // dispatch current step title
        appStore.dispatch(updateGui({
            currentStepTitle: getQuotationsTitle()
        }));

        // set map static image url
        this.setMapStaticImageUrl();

        // request quotations to http web api
        httpClientConfig({
            baseUrl: getConfItem('api.' + process.env.NODE_ENV + '.baseUrl')
        });
        this.fetchQuotations();

        // Google Analytics track page
        gaTrackPage();
    }

    /**                                                                              Redirect
     * ======================================================================================
     */

    /**
     * Navigates back to Wizard Step 5
     */
    navigateBack() {
        this.setState((state, props) => ({
            redirect: urlRewriteWithSearchStringFromState('wizard/step5')
        }));
    }

    navigateToWizard() {
        this.setState((state, props) => ({
            redirect: urlRewriteWithSearchStringFromState('wizard/step1')
        }));
    }

    /**                                                                                  Data
     * ======================================================================================
     */

    /**
     * Triggers quotations fetch from the SolarConfig API
     */
    fetchQuotations() {
        this.setState((state, props) => ({
            loading: true
        }));
        httpClientQuotationsGet(quotationsGetRequestData())
            /**
             * @param {Object} response
             *  .config (axios config)
             *  .data (actual data)
             *  .headers (http headers)
             *  .request (xmlhttp)
             */
            .then(function(response) {
                this.onFetchQuotationsResponse(response.data);
            }.bind(this));
    }

    /**
     * Fetch quotations response handler
     * @param {Object} response 
     */
    onFetchQuotationsResponse(response) {

        // switch loading to false
    
        this.setState((state, props) => ({
            loading: false
        }));

        // read response data
        const uuid = response.uuid;
        const valutazione = response.valutazione;
        const quotations = response.quotations;
        const altquotations = response.altquotations;
        const bestquotationsRef = response.bestquotations;
        const bestquotations = response[bestquotationsRef];
        const batteries = response.batteries;

        /**
         * update application store quotations (.quotations) with response data info
         * ...for checkbox options, we need a boolean indicating if the option has been choosen, not the option cost
         * ...this is why we assign false here and not the value (the cost) stored in the response
         */
        this.updateStoreQuotations(quotations, {
            uuid,
            valutazione
        });

        // update application store altquotations (.altquotations) with response data
        appStore.dispatch(updateAltquotations({
            altquotations: altquotations
        }));

        // update application store bestquotations (.bestquotations) with response data
        appStore.dispatch(updateBestquotations({
            bestquotations
        }));

        // update application store batteries (.batteries) with response data
        appStore.dispatch(updateBatteries({
            batteries
        }));

        // update state (this.state.valutazione, this.state.data) with response data
        // [TODO] : refactor: use this.updateState(quotations, options = {}) function insteas
        this.setState((state, props) => {
            return {
                valutazione: valutazione,
                data: {
                    ...quotations,
                    produzioneAnnualeGarantita: {
                        ...quotations.produzioneAnnualeGarantita,
                        ...this.getProduzioneAnnualeGarantitaWithOttimizzatori(quotations.produzioneAnnualeGarantita)
                    }
                },
                totals: { ...quotations.totale },
                importoDetrazione: { ...quotations.importoDetrazione }
            };
        });

    }

    getProduzioneAnnualeGarantitaWithOttimizzatori(produzioneAnnualeGarantita) {
        return {
            basicWithOttimizzatori: produzioneAnnualeGarantitaMultiply(produzioneAnnualeGarantita.basic, 1.05),
            plusWithOttimizzatori: produzioneAnnualeGarantitaMultiply(produzioneAnnualeGarantita.plus, 1.05)
        };
    }

    /**
     * Updates the Component state
     * @param {Object} quotations 
     * @param {Object} options
     *  .valutazione {Object} optional
     */
    updateState(quotations, options = {}) {

        this.setState((state, props) => {

            // valutazione, if not passed in, gets the redux store value
            const valutazione = options.valutazione || props.quotations.valutazione;

            return {
                valutazione: valutazione,
                data: {
                    ...quotations,
                    produzioneAnnualeGarantita: {
                        ...quotations.produzioneAnnualeGarantita,
                        ...this.getProduzioneAnnualeGarantitaWithOttimizzatori(quotations.produzioneAnnualeGarantita)
                    }
                },
                totals: { ...quotations.totale },
                importoDetrazione: { ...quotations.importoDetrazione }

            };

        });

    }

    /**
     * Updates quotations totale in Component state (this.state.data.totale)
     * @param {String} quotationType 
     *  Range
     *      ['basic'|'plus']
     * @param {String} Number 
     */
    updateStateTotale(quotationType, value) {
        this.setState((state, props) => {

            const updateObj = {
                data: {
                    totale:{}
                }
            };
            updateObj.data.totale[quotationType] = value;

            const updateTotale = {
                ...state.data.totale,
                ...updateObj.data.totale
            };

            return {
                ...state,
                data: {
                    ...state.data,
                    totale: updateTotale,
                }
            };

        });
    }

    updateStateImportoDetrazioneScontato(quotationType, value) {
        this.setState((state, props) => {

            const updateObj = {
                data: {
                    importoDetrazione: {}
                }
            };
            updateObj.data.importoDetrazione[quotationType] = value;

            const updateImportoDetrazione = {
                ...state.data.importoDetrazione,
                ...updateObj.data.importoDetrazione
            };

            return {
                ...state,
                data: {
                    ...state.data,
                    importoDetrazione: updateImportoDetrazione
                }
            };

        });
    }

    /**
     * Update the application store quotations (.quotations) with quotations
     * uuid and valutazione are passed in only on /quote service response load, then just read again from the application store
     * For checkbox options (accumulatoreProposto, ottimizzatori), we need a boolean indicating if the option has been choosen, not the option cost
     * This is why we assign it to the redux store value (which is always boolean) if the option is received as !boolean (backend must be updated to send boolean type)
     * @param {Object} quotations 
     * @param {Object} options 
     *  .valutazione {Object} optional
     *      Passed in only on /quote service response load
     *  .uuid {String} optional
     *      Passed in only on /quote service response load
     */
    updateStoreQuotations(quotations, options = {}) {

        // quotations fields evaluated with the redux store value, if not received as arguments
        const uuid = options.uuid || this.props.quotations.uuid;
        const valutazione = options.valutazione || this.props.quotations.valutazione;
        const selectedImpianto = options.selectedImpianto || this.props.quotations.selectedImpianto;
        const selectedQuotation = options.selectedQuotation || this.props.quotations.selectedQuotation;

        // add produzioneAnnualeGarantitaWithOttimizzatori
        quotations.produzioneAnnualeGarantita = {
            ...quotations.produzioneAnnualeGarantita,
            ...this.getProduzioneAnnualeGarantitaWithOttimizzatori(quotations.produzioneAnnualeGarantita)
        };

        appStore.dispatch(updateQuotations({
            quotations: {
                uuid,
                valutazione,
                selectedImpianto,
                selectedQuotation,
                snapshot: quotations,
                basic: {
                    total: quotations.totale.basic,
                    options: {
                        //accumulatoreProposto: (typeof(quotations.accumulatoreProposto.options.basic) === 'boolean') ? quotations.accumulatoreProposto.options.basic : this.props.quotations.basic.options.accumulatoreProposto,
                        potenza: quotations.potenza.basic,
                        numeroPannelli: quotations.numeroPannelli.basic,
                        capacitaSistemaAccumulo: quotations.capacitaSistemaAccumulo.basic,
                        ottimizzatori: (typeof(quotations.ottimizzatori.options.basic) === 'boolean') ? quotations.ottimizzatori.options.basic : this.props.quotations.basic.options.ottimizzatori
                    }
                },
                plus: {
                    total: quotations.totale.plus,
                    options: {
                        //accumulatoreProposto: (typeof(quotations.accumulatoreProposto.options.plus) === 'boolean') ? quotations.accumulatoreProposto.options.plus : this.props.quotations.plus.options.accumulatoreProposto,
                        potenza: quotations.potenza.plus,
                        numeroPannelli: quotations.numeroPannelli.plus,
                        capacitaSistemaAccumulo: quotations.capacitaSistemaAccumulo.plus,
                        ottimizzatori: (typeof(quotations.ottimizzatori.options.plus) === 'boolean') ? quotations.ottimizzatori.options.plus : this.props.quotations.plus.options.ottimizzatori
                    }
                }
            }
        }));

    }

    /**
     * Updates quotations snapshot in application store (.quotations.snapshot)
     * @param {Object} quotationsSnapshot
     */
    updateStoreQuotationsSnapshot(quotationsSnapshot) {
        appStore.dispatch(updateQuotationsSnapshot({
            snapshot: quotationsSnapshot
        }));      
    }

    /**
     * Updates quotations totale and options in application store
     * @param {String} quotationType
     *  Range
     *      ['basic'|'plus']
     * @param {Number} totalValue
     *  Sample
     *      12901.39
     * @param {String} optionId
     *  Sample
     *      'accumulatoreProposto'
     * @param {Boolean} optionValue 
     */
    updateStoreOptionsAndTotale(quotationType, totalValue, optionId, optionValue) {

        appStore.dispatch(updateQuotationsTotal({
            quotationType: quotationType,
            totalValue: totalValue
        })); 

        appStore.dispatch(updateQuotationsOption({
            quotationType: quotationType,
            optionId: optionId,
            optionValue: optionValue
        })); 

    }

    /**
     * Updates quotations selected quotation in application store (.quotations.selectedQuotation)
     * @param {String} quotationType
     *  Range
     *      ['basic'|'plus']
     */
    updateStoreSelectedQuotation(quotationType) {
        appStore.dispatch(updateQuotationsSelectedQuotation({
            quotationType: quotationType
        }));        
    }

    /**
     * Updates quotations selected impianto in application store (.quotations.selectedImpianto)
     * @param {String} impianto
     *  Range
     *      ['basic'|'basic-accumulo'|'plus'|'plus-accumulo'|'plus-s'|'plus-s-accumulo']
     */
    updateStoreSelectedImpianto(impianto) {
        appStore.dispatch(updateQuotationsSelectedImpianto({
            impianto: impianto
        }));        
    }

    /**
     * Sets this.state.mapStaticImageUrl driven by the application store
     */
    setMapStaticImageUrl() {
        const appState = appStore.getState();
        const map = getItem('map.refs.map', appState);
        const polygon = getItem('map.refs.polygon', appState);
        const responsiveState = getItem('gui.responsive', appState);
        const mapImageViewport = getConfItem('gui.map.mapStaticImage.sizeInQuotationsComponent');//'331x204';
        const mapImageUrl = mapImageUrlInReactComponent(map, polygon, responsiveState, mapImageViewport);
        this.setState((state, props) => ({
            mapStaticImageUrl: mapImageUrl || imgLabelsDummyUrl
        }));
    }

    /**                                                                                Events
     * ======================================================================================
     */

    /**
     * Event listener for clicks on choose quotation (basic or plus) buttons
     * @param {Event} evt 
     */
    onQuoteChooseButtonClick(evt) {

        const selectedClassName = 'slc-button-quotation-selected';

        // is the checkbox selected?
        const selected = evt.target.classList.contains(selectedClassName);//evt.target.checked;

        // parent element to read important infos from parent element attributes
        const parent = evt.target.parentElement;

        // id of the checkbox (containes both the quotation type and the option name)
        const dataOptionId = parent.getAttribute('data-option-id');                         // Sample: 'ottimizzatori-plus'

        // the quotation type ('basic'|'plus')
        const quotationType = quotationTypeFromOptionId(dataOptionId);                      // sample: 'plus'
        
        // unselect if already selected
        if (selected) {

            // update selected quotation model and gui
            evt.target.classList.remove(selectedClassName);

            // update the application store selected quotation
            const selectedQuotation = null;
            this.updateStoreSelectedQuotation(selectedQuotation);

            // update the Gui quotation selector choose buttons
            // this.quotationSelectorCheckboxesGui(dataOptionId, selected); FOR CHECKBOXES, MUST BE IMPLEMENTED FOR CHOOSE BUTTONS
            // TODO
            this.quotationSelectorChooseButtonsGui(dataOptionId, false, selectedClassName);

            // update the cessioneDelCredito values gui, depending on the selected quotation type
            this.totaleScontatoValueGui(false, quotationType);

            // update form emphasize gui
            this.formEmphasizeManageGui(selectedQuotation);

        // select if not selected
        } else {

            // update selected quotation model and gui
            evt.target.classList.add(selectedClassName);

            // update the application store selected quotation
            const selectedQuotation = quotationType;
            this.updateStoreSelectedQuotation(selectedQuotation);

            // update the Gui quotation selector choose buttons
            // this.quotationSelectorCheckboxesGui(dataOptionId, selected); FOR CHECKBOXES, MUST BE IMPLEMENTED FOR CHOOSE BUTTONS
            // TODO
            this.quotationSelectorChooseButtonsGui(dataOptionId, true, selectedClassName);

            // update the cessioneDelCredito values gui, depending on the selected quotation type
            this.totaleScontatoValueGui(true, quotationType);

            // update form emphasize gui
            this.formEmphasizeManageGui(selectedQuotation);
        }

        // update the application store selected impianto
        // get the selected quotation/column (basic or plus?)
        const selectedQuotation = getItem('quotations.selectedQuotation', appStore.getState());
        // if no quotation is selected, selected impianto is null also
        if (selectedQuotation === null) {
            this.updateStoreSelectedImpianto(null);
        // if some quotation is selected
        } else {
            // if the user interacted option checkbox belongs to the selected quotation/column, calculate selected impianto based on the user choices
            if (quotationType === selectedQuotation) {
                let selectedImpianto = getImpiantoBySelection(quotationType, getOptionsSelectionState());
                this.updateStoreSelectedImpianto(selectedImpianto);
            }
        }
    }

    /**
     * Event listener for quotations checkbox options
     * @param {Event} evt 
     */
    onCheckboxChange(evt) {

        // is the checkbox selected?
        const selected = evt.target.checked;

        // parent element to read important infos from parent element attributes
        const parent = evt.target.parentElement;

        // id of the checkbox (containes both the quotation type and the option name)
        const dataOptionId = parent.getAttribute('data-option-id');                         // Sample: 'ottimizzatori-plus'

        // the quotation type ('basic'|'plus')
        const quotationType = quotationTypeFromOptionId(dataOptionId);                      // sample: 'plus'

        // is the checkbox a quotation selector or a quotation update one?
        const isQuotationSelector = parent.getAttribute('class').indexOf('scegli') != -1;

        // a row 'Scegli il tuo sistema' checkbox was checked -> select quotation (ABANDONED: the quotation selector is a button now)
        if (isQuotationSelector) {

            // update the application store selected quotation
            const selectedQuotation = (selected) ? quotationType : null ;
            this.updateStoreSelectedQuotation(selectedQuotation);

            // update the Gui quotation selector checkboxes
            this.quotationSelectorCheckboxesGui(dataOptionId, selected);

            // update the cessioneDelCredito values gui, depending on the selected quotation type
            this.totaleScontatoValueGui(selected, quotationType);

            this.formEmphasizeManageGui(selectedQuotation);
                
        // a value checkbox was checked -> update quotations
        } else {

            const optionName = optionNameFromOptionId(dataOptionId);                        // Sample: 'ottimizzatori'                       
            const totalUpdated = getTotaleBySelection(dataOptionId, this.state.totals);
            const importoDetrazioneUpdated = getImportoDetrazioneBySelection(dataOptionId, this.state.importoDetrazione);

            this.updateStateTotale(quotationType, totalUpdated);
            this.updateStateImportoDetrazioneScontato(quotationType, importoDetrazioneUpdated);
            this.updateStoreOptionsAndTotale(quotationType, totalUpdated, optionName, selected);

        }

        // update the application store selected impianto
        // get the selected quotation/column (basic or plus?)
        const selectedQuotation = getItem('quotations.selectedQuotation', appStore.getState());
        // if no quotation is selected, selected impianto is null also
        if (selectedQuotation === null) {
            this.updateStoreSelectedImpianto(null);
        // if some quotation is selected
        } else {
            // if the user interacted option checkbox belongs to the selected quotation/column, calculate selected impianto based on the user choices
            if (quotationType === selectedQuotation) {
                let selectedImpianto = getImpiantoBySelection(quotationType, getOptionsSelectionState());
                this.updateStoreSelectedImpianto(selectedImpianto);
            }
        }
        
    }

    /**
     * Event listener for quotations select options
     * @param {Event} evt 
     */
    onSelectChange(evt) {

        // parent element to read important infos from parent element attributes
        const parent = evt.target.parentElement;

        // id of the checkbox (containes both the quotation type and the option name)
        const dataOptionId = parent.getAttribute('data-option-id');                         // Sample: 'ottimizzatori-plus'

        // the quotation type ('basic'|'plus')
        const quotationType = quotationTypeFromOptionId(dataOptionId);                      // sample: 'plus'

        // the data id                                                                      // sample: 'potenza'
        const dataId = optionNameFromOptionId(dataOptionId);

        // find the quotations determined by the current inputs snapshot (which choices the user has done with the quotations inputs, if any)
        const quotations = readQuotationFromReduxStateByInputsSnapshot(appStore.getState(), quotationType);
        
        // Update Redux store quotations.snapshot with found quotations
        this.updateStoreQuotations(quotations);

        // Update Component state with quotations
        this.updateState(quotations);

        // Update Component state totale with quotation
        const totalUpdated = getTotaleBySelection(dataOptionId, quotations.totale);
        const importoDetrazioneUpdated = getImportoDetrazioneBySelection(dataOptionId, quotations.importoDetrazione);
        this.updateStateTotale(quotationType, totalUpdated);
        this.updateStateImportoDetrazioneScontato(quotationType, importoDetrazioneUpdated);
        
    }
    
    /**
     * Click event handler for the full page
     * Clicking anywhere in the page causes deleting the currently shown label info popup, if any
     * This function is passed down to child components as a prop:
     *  1. 'this' will be the ref to the leaf components (QuotationsDesktop.js, QuotationsMobile.js)
     *  2. the addEventListener function is actually registered in the leaf components (QuotationsDesktop.js, QuotationsMobile.js)
     * Deletion occurs if:
     *  1. Click occurred on any element except 
     *      .slc-data-label-info
     *      .slc-data-label-info-content__body
     *      in which case, deletion is already managed by the click handlers on those elements
     *  2. A label info popup is currently shown
     * @param {Event} evt 
     */
    onLabelInfoClickAnywhere(evt) {
        // get the target css class name
        const className = evt.target.getAttribute('class');
        // if target is any element except .slc-data-label-info and .slc-data-label-info-content__body, proceed
        if (className !== 'slc-data-label-info' && className !== 'slc-data-label-info-content__body') {
            // if any label info is shown, delete it
            if (this.labelInfoShowedNow) {
                this.labelInfoContentDelete(this.labelInfoShowedNow);
            }
        }
    }

    /**                                                                                   Gui
     * ======================================================================================
     */

    quotationEmphasizeGui(quotationType) {}

    /**
     * Handles the form emphasize/unemphasize Gui
     * When a quote is selected, the form is emphasized
     * When a quote is unselected, the form is unemphasized
     * @param {String} selectedQuotation 
     *  ['basic'|'plus']
     */
    formEmphasizeManageGui(selectedQuotation) {
        const formElement = document.querySelector('.slc-page-quotations-form');
        const mainElement = document.querySelector('.slc-page-quotations-main');
        if (selectedQuotation) {
            this.formEmphasizeGui(mainElement, formElement);
        } else {
            this.formUnemphasizeGui(mainElement, formElement);
        }
    }
    
    /**
     * Emphasizes the form Gui
     * 1. Shrinks the main pane from l12 to l8 width
     * 2. Shows the form pane
     * 3. Scrolls the page to the form pane
     * 4. Animates the form title as text typing
     * 5. Put focus onto the form's name text field
     * @param {HTMLElement} mainElement 
     * @param {HTMLElement} formElement 
     */
    formEmphasizeGui(mainElement, formElement) {
        // 1.
        const mainClass = mainElement.getAttribute('class').replace('l12', 'l8');
        mainElement.setAttribute('class', mainClass);
        // 2.
        const formClass = formElement.getAttribute('class').replace('hide', '');
        formElement.setAttribute('class', formClass);
        // 3.
        formElement.scrollIntoView();
        // 4.
        /* Uncomment this to enable the Form title animation (must remove the text from the Form Component title, also)
        const formTitleElement = document.querySelector('.slc-quotations-form__title');
        const formTitleAnimation = new AnimatedTyping('Inserisci i tuoi dati per ricevere un preventivo dettagliato su misura per te', {speed: 50}, function(txt, fullTxt, options) {
            formTitleElement.innerHTML = txt;
        }).animate();
        */
        // 5.
        const inputNameElement = document.querySelector('.slc-form-input-text-widget__input-nome');
        inputNameElement.focus();
    }

    /**
     * Unmphasizes the form Gui
     * Does not need any implementation so far
     * @param {HTMLElement} mainElement 
     * @param {HTMLElement} formElement 
     */
    formUnemphasizeGui(mainElement, formElement) {}

    /**
     * Sets the selectedButtonId button to selected state
     * Deselects all the other 'scegli-...' buttons
     * 
     * Updates form validation related features:
     *  - if selected === true:
     *      - removes the gui invalid view
     *      - triggers form submit button state manager
     */
    quotationSelectorChooseButtonsGui(selectedButtonId, selected, selectedClassName) {

        document.querySelectorAll('[data-option-id^="scegli-"] .slc-button')
            .forEach(function(button) {
                let buttonId = button.parentElement.getAttribute('data-option-id');
                if (buttonId == selectedButtonId) {
                    if (selected) {
                        button.classList.add(selectedClassName);
                    } else {
                        button.classList.remove(selectedClassName);
                    }
                } else {
                    button.classList.remove(selectedClassName);
                }
            });

        if (selected) {
            const formGuiValidationElement = document.querySelector('.slc-data-scegli');
            formGuiValidationElement.classList.remove('slc-form-item-validation-error');
        }
        
    }

    /**
     * Sets the selectedCheckboxId checkbox checked state to selected
     * Deselects all the other 'scegli-...' checkboxes
     * 
     * Updates form validation related features:
     *  - if selected === true:
     *      - removes the gui invalid view
     *      - triggers form submit button state manager
     */
    quotationSelectorCheckboxesGui(selectedCheckboxId, selected) {

        document.querySelectorAll('[data-option-id^="scegli-"] .slc-checkbox')
            .forEach(function(checkbox) {
                let checkboxId = checkbox.parentElement.getAttribute('data-option-id');
                if (checkboxId == selectedCheckboxId) {
                    checkbox.checked = selected;
                } else {
                    checkbox.checked = false;
                }
            });

        if (selected) {
            const formGuiValidationElement = document.querySelector('.slc-data-scegli');
            formGuiValidationElement.classList.remove('slc-form-item-validation-error');
        }
        
    }

    /**
     * Updates the cessioneDelCredito values gui, depending on the selected quotation type
     * Hilites the selected quotation value
     * Dehilites the other quotation value
     * @param {Boolean} selected 
     * @param {String} quotationType 
     *  ['basic'|'plus']
     */
    totaleScontatoValueGui(selected, quotationType) {

        const $cessioneDelCreditoValues = document.querySelectorAll('.slc-data-totaleScontato .slc-data-value');

        // cessione del credito DOM elements don't exist
        if ($cessioneDelCreditoValues.length === 0) {
            return;
        }

        // css class affecting the HTML elements gui
        const selectedCssClassName = 'slc-data-value-selected';
        // dehiltes all the values (both basic and plus)
        $cessioneDelCreditoValues
            .forEach(function(dataValueElement) {
                dataValueElement.classList.remove(selectedCssClassName);
            });
        // hilites the selected value, if this is the case
        if (selected) {
            const dataValueElement = document.querySelector('.slc-data-totaleScontato .slc-data-value-' + quotationType);
            dataValueElement.classList.add(selectedCssClassName);
        }
    }

    /**
     * Updates the Produzione Annuale Garantita (Energia garantita (5 anni)) gui value based upon if the Ottimizzatori di potenza (Desidero ottimizzatori di potenza) is selected or not
     * The value is fetched from this.state.data.produzioneAnnualeGarantita, reading one of the four keys:
     *  'basic'
     *  'basicWithOttimizzatori'
     *  'plus'
     *  'plusWithOttimizzatori'
     * @param {String} quotationType
     *  ['basic'|'plus']
     * @param {Boolean} ottimizzatoriSelected
     */
    produzioneAnnualeGarantitaFromOttimizzatori(quotationType, ottimizzatoriSelected, produzioneAnnualeGarantita) {

        if (!produzioneAnnualeGarantita) {
            produzioneAnnualeGarantita = this.state.data.produzioneAnnualeGarantita;
        }
        
        const produzioneAnnualeGarantitaElement = document.querySelector('.slc-data-produzioneAnnualeGarantita .slc-data-value-' + quotationType);
        const valueKey = (ottimizzatoriSelected) ? quotationType + 'WithOttimizzatori' : quotationType; // 'basic'|'basicWithOttimizzatori'|'plus'|'plusWithOttimizzatori'
        const value = produzioneAnnualeGarantita[valueKey]; // '6.000/kWh annuo'|6.300/kWh annuo
        
        produzioneAnnualeGarantitaElement.innerHTML = formatProduzioneAnnualeGarantita(value); // '6.000,00/kWh annuo'

    }

    /**                                                                                Render
     * ======================================================================================
     */

    /**
     * Returns content based on responsive state
     * At the moment behaves always as a 'desktop' state
     */
    getContent() {
        return <PageQuotationsDestkop
                    navigateBack={ this.navigateBack.bind(this) }
                    navigateToWizard={ this.navigateToWizard.bind(this) }
                    mapStaticImageUrl={ this.state.mapStaticImageUrl }
                    loading={ this.state.loading }
                    config={ this.state.config }
                    valutazione={ this.state.valutazione }
                    data={ this.state.data }
                    onCheckboxChange={ this.onCheckboxChange.bind(this) }
                    onSelectChange={ this.onSelectChange.bind(this) }
                    onQuoteChooseButtonClick={ this.onQuoteChooseButtonClick.bind(this) }
                    onLabelInfoClickAnywhere= { this.onLabelInfoClickAnywhere} />;
        /**
        return (props.gui.responsive !== 'mobile') ?
            <PageQuotationsDestkop onSubmit={onSubmit} /> :
            <PageQuotationsMobile onSubmit={onSubmit} />;
         */
    }

    /**
     * Returns the page css class name
     * @return {String}
     *  Sample
     *      'slc-page slc-page-quotations responsive-desktop
     */
    pageClassName() {
        return pageComponentCssClass('quotations', this.props.gui.responsive);
    }

    render() {
        if (this.state.redirect !== false) {
            return <Redirect to={ this.state.redirect } />;
        } else {
            const loading = (this.state.loading) ? <Loading label={getLabel('gui.loading')} /> : null ;
            return (
                <div className={ this.pageClassName() }>
                    <div className="row slc-page-header">
                        <div className="col s12">
                            <Header />
                        </div>
                    </div>
                    {loading}
                    <div className="row slc-page-body">
                        { this.getContent() }
                    </div>
                </div>
            );
        }
    }

}

export default connect(mapStateToProps)(PageQuotations);