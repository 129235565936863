/**
 * PageHome Component
 * Connected to redux
 * 
 * @prop gui {String}
 *  ['desktop'|'mobile']
 * @state {Object}
 *  .navPath {String}
 */

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { connect } from "react-redux";

import appStore from '../../redux/store/appStore';
import {
    storeReset,
    updateUser
} from '../../redux/actions/actions';

import Header from '../Header';
import PageHomeContent from './PageHomeContent';
import Footer from '../Footer';

import { gaTrackPage } from '../../helpers/google-analytics-helper';
import { navigationByWizardControlsStart } from '../../helpers/browser-navigation-helper';
import { initialize as autocompleteInitialize } from '../../helpers/google-places-autocomplete-helper';
import { warn as guiFeedbackWarn } from '../../libs/gui-feedback';
import getResponsiveContent from '../../helpers/responsive-content-home-helper';

import { pageComponentCssClass } from '../../helpers/components-helper';

import './Page.css';
import './PageHome.css';

const mapStateToProps = state => {
    return {
        gui: state.gui
    };
};

const title = 'Pochi passi per risparmiare con i pannelli solari';
const infos = 'Scopri il Sistema Solare più adatto a te e calcola il prezzo';

class PageHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            navPath: null
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        appStore.dispatch(storeReset());
        // Google Analytics track page
        gaTrackPage();
    }

    autocompleteInit() {
        try {
            const autocomplete = autocompleteInitialize({
                element: document.getElementById('user-address')
            });
        } catch (e) {
            console.error(e.name + ': ' + e.message);
        }
    }

    onSubmit() {

        const userAddress = document.getElementById('user-address').value;
        const userEmail = document.getElementById('user-email').value;

        if (userAddress === '') {
            guiFeedbackWarn('E\' necessario inserire un indirizzo');
            return;
        }

        appStore.dispatch(updateUser({
            address: userAddress,
            email: userEmail
        }));
        navigationByWizardControlsStart();
        this.setState((state, props) => ({
            navPath: '/wizard'
        }));
        
    }

    /**
     * Returns the page css class name
     * @return {String}
     *  Sample
     *      'slc-page slc-page-home responsive-desktop
     */
    pageClassName () {
        return pageComponentCssClass('home', this.props.gui.responsive);
    }

    render() {

        if (this.state.navPath) {
            return <Redirect to={{pathname: this.state.navPath}} />
        } else {
            return (
                <div className={this.pageClassName()}>
                    <div className="row slc-page-header">
                        <div className="col s12">
                            <Header title={false} />
                        </div>
                    </div>
                    <div className="slc-page-body">
                        <PageHomeContent
                            responsive={this.props.gui.responsive}
                            onSubmit={this.onSubmit}
                            autocompleteInit={this.autocompleteInit}
                            title={title}
                            infos={infos}
                            btnTxt={getResponsiveContent('btnTxt', this.props.gui.responsive)}
                            imagePath={ getResponsiveContent('image', this.props.gui.responsive) } />
                    </div>
                    <div className="slc-page-footer">
                        <Footer />
                    </div>
                </div>
            );
        }
    }
    
};

export default connect(mapStateToProps)(PageHome);